import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MESSAGE_TYPE } from '../constants/enums';
@Injectable({ providedIn: 'root' })
export class ToastService {
  private renderer: Renderer2;
  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  openSnackBar(message: string, action: string, type: MESSAGE_TYPE) {
    // Remove element before rendering
    const ele = document.getElementsByClassName('snackbar');

    for (let i = 0; i < ele.length; i++) ele[i].remove();

    // Create container
    const toastContainer = this.renderer.createElement('div');

    // Create icon
    const icon = this.renderer.createElement('i');
    this.renderer.setAttribute(icon, 'class', `fa ${this.getClasses(type)} snakbar-icon`);

    this.renderer.appendChild(toastContainer, icon);

    const span = this.renderer.createElement('span');
    this.renderer.setAttribute(span, 'class', 'snakbar-message');

    this.renderer.setAttribute(toastContainer, 'class', 'snackbar show-bar');
    this.renderer.appendChild(span, this.renderer.createText(message));
    this.renderer.appendChild(toastContainer, span);

    // Create message
    const button = this.renderer.createElement('input');
    this.renderer.setAttribute(button, 'type', 'button');
    this.renderer.setAttribute(button, 'value', action);
    this.renderer.setAttribute(button, 'class', 'btn btn-sm btn-flat btn-warning pull-right');

    setTimeout(() => {
      this.renderer.removeChild(document.body, toastContainer);
    }, 2000);

    // Close snakbar
    this.renderer.listen(button, 'click', () => {
      this.renderer.removeChild(document.body, toastContainer);
    });

    this.renderer.appendChild(toastContainer, button);
    this.renderer.appendChild(document.body, toastContainer);

    return toastContainer;
  }

  getClasses(type: MESSAGE_TYPE) {
    switch (type) {
      case MESSAGE_TYPE.SUCCESS:
        return 'fa-check-circle-o text-green';
      case MESSAGE_TYPE.WARNING:
        return 'fa-info-circle text-yellow';
      default:
        return 'fa-times-circle-o text-red';
    }
  }
}
