<lte-layout-wrapper [isAdminLayoutType]="true">
  <lte-layout-header logoLink="/" headerTitle="Provider Attestations" [isAdminLayoutType]="true">
    <lte-layout-header-logo>Provider Attestation</lte-layout-header-logo>
    <lte-layout-header-logo-mini><b>PA Test</b></lte-layout-header-logo-mini>
  </lte-layout-header>
  <div class="col-md-12 bar"></div>
  <lte-layout-content [isAdminLayoutType]="true">
    <div class="pt-0 mt-0">
      <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="searchbox">
          <form [formGroup]="formGroup">
            <div class="row" style="align-items: center;">
              <div class="col-md-9" style="color:#002677">
                <span><b>Reports Dashboard</b></span>
              </div>
              <div class="col-md-3 text-end">
                <div class="d-flex">
                <select class="form-control fieldRadius" formControlName="ddlAttestationPeriod">
                  <option value="">Select attestation period</option>
                  <option *ngFor="let item of attestationPeriod$" [value]="item.name">{{item.name}}</option>
                </select>
                <button type="button" class="btn btn-primary btn-attPeriod ml-3"
                  (click)="getReportCardCount()">Apply</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2" (click)="getAttestationReport('TotalProviders')">
          <div class="card rpt-card-title1" [ngClass]="reportFilter == 'TotalProviders' ? 'cardSelected' : ''">
            <div class="card-title fw-bold">
              <span style="margin-left: 1px !important;">Total Providers</span>
            </div>
            <div class="">
              <b><span class="" style="margin-left: 1px !important; color: #002677;">{{providerCount}}</span></b>
            </div>
          </div>
        </div>
        <div class="col-md-2" (click)="getAttestationReport('AttestationCompleted')">
          <div class="card rpt-card-title2" [ngClass]="reportFilter == 'AttestationCompleted' ? 'cardSelected' : ''">
            <div class="card-title">
              <span style="margin-left: 1px !important;">Attestation Completed</span>
            </div>
            <div class="">
              <span class="d-block" style="margin-left: 1px !important;"><small> With Changes - </small><b style="color: #28a745">{{AttestationCompletedWithChangeCount}}</b></span>
              <span class="d-block" style="margin-left: 1px !important;"><small> Without Changes - </small><b style="color: #28a745">{{AttestationCompletedWithoutChangeCount}}</b></span>
            </div>
          </div>
        </div>
        <div class="col-md-2" (click)="getAttestationReport('AttestationDue')">
          <div class="card rpt-card-title3" [ngClass]="reportFilter == 'AttestationDue' ? 'cardSelected' : ''">
            <div class="card-title">
              <span style="margin-left: 1px !important;">Attestations Due</span>
            </div>
            <div class="">
              <b><span class="" style="margin-left: 1px !important;color: #78caf9">{{attestationDueCount}}</span></b>
            </div>
          </div>
        </div>
        <div class="col-md-2" (click)="getAttestationReport('SecondaryOutreach')">
          <div class="card rpt-card-title7" [ngClass]="reportFilter == 'SecondaryOutreach' ? 'cardSelected' : ''">
            <div class="card-title">
              <span style="margin-left: 1px !important;">Attestation for Secondary Outreach</span>
            </div>
            <div class="">
              <b><span class="" style="margin-left: 1px !important;color: #ffbf00">{{secondaryOutreachCount}}</span></b>
            </div>
          </div>
        </div>    
        <div class="col-md-2" (click)="getAttestationReport('TertiaryOutreach')">
          <div class="card rpt-card-title8" [ngClass]="reportFilter == 'TertiaryOutreach' ? 'cardSelected' : ''">
            <div class="card-title">
              <span style="margin-left: 1px !important;">Attestation for Tertiary Outreach</span>
            </div>
            <div class="">
              <b><span class="" style="margin-left: 1px !important;color: #e1c16e">{{tertiaryOutreachCount}}</span></b>
            </div>
          </div>
        </div>  
        <div class="col-md-2" (click)="getAttestationReport('ActiveOutbound')">
          <div class="card rpt-card-title6" [ngClass]="reportFilter == 'ActiveOutbound' ? 'cardSelected' : ''">
            <div class="card-title">
              <span style="margin-left: 1px !important;">Active Outbound</span>
            </div>
            <div class="">
              <span class="d-block" style="margin-left: 1px !important;"><small> Email - </small><b style="color: #f7479c">{{FailedEmailOutreachCount}}</b></span>
              <span class="d-block" style="margin-left: 1px !important;"><small> Fax - </small><b style="color: #f7479c">{{FailedFaxOutreachCount}}</b></span>
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4" (click)="getAttestationReport('Exceptions')">
          <div class="card rpt-card-title4" [ngClass]="reportFilter == 'Exceptions' ? 'cardSelected' : ''">
            <div class="card-title">
              <span style="margin-left: 1px !important;">Exceptions</span>
            </div>
            <div class="">
              <b><span class="" style="margin-left: 1px !important;color: #ff5349">{{exceptionrCount}}</span></b>
            </div>
          </div>
        </div>                  
        <div class="col-md-2 mt-4" (click)="getAttestationReport('Failures')">
          <div class="card rpt-card-title5" [ngClass]="reportFilter == 'Failures' ? 'cardSelected' : ''">
            <div class="card-title">
              <span style="margin-left: 1px !important;">Notification Failures</span>
            </div>
            <div class="">
              <b><span class="" style="margin-left: 1px !important;color: #dc3545">{{failureCount}}</span></b>
            </div>
          </div>
        </div>        
      </div>      
    
    <div class="row" style="margin-top: 25px !important; color: #030d68;">
      <div class="col-md-9">
          <span><b>Attestation Period {{attPeriod}}</b></span>
      </div>
      <div class="col-md-3" style="text-align: right; cursor: pointer;" (click)="downloadAttestationReport()">
        <span><a class="fa fa-file-excel-o px-2"></a><b>Download Report</b></span>
      </div>      
    </div>    
    <div class="row" style="margin-top: 25px !important;">
      <div class="col-md-12 ">
          <ngx-datatable #table
          class="bootstrap pdi-grid"
          [columns]="columns"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          rowHeight="auto"
          [limit]="currentPageSize"
          [rows]="reportAttestations$"
          [sorts]="[{ prop: 'name', dir: 'asc' }]"
          (sort)="onSort($event)"    
          >        
          <ngx-datatable-column name="Name" prop="name" [width]="200">
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
              <span class="span-textoverflow">{{row.lastName + ', ' + row.firstName}}</span>
              </ng-template>
          </ngx-datatable-column>        
          <ngx-datatable-column name="NPI" prop="npi"></ngx-datatable-column>
          <ngx-datatable-column name="CDO" prop="cdo"></ngx-datatable-column>
          <ngx-datatable-column name="TIN" prop="tin"></ngx-datatable-column>
          <ngx-datatable-column name="Email/Fax" prop="email" [width]="150">
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <span class="span-textoverflow">{{value}} </span>
              </ng-template>
          </ngx-datatable-column>          
          <ngx-datatable-column name="Attestation Status" prop="isAttested" [width]="180">
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  {{value==true ? 'Attested' : 'Not Attested'}}
              </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Attested Date" prop="attestedDate">            
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                {{value | date: 'MM/dd/yyyy'}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Attested By" prop="attestedBy"></ngx-datatable-column>
          <ngx-datatable-column name="Notification Status" prop="description" [width]="200"></ngx-datatable-column>
          <ngx-datatable-column name="Modified" prop="modified">
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  {{value==true ? 'Yes' : 'No'}}
              </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
              <ng-template
              ngx-datatable-footer-template 
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"              
              >
              <table style="width: 100%;" *ngIf="totalCount==0 ? false: true">
                  <tr>
                      <td style="width:30%">            
                          Showing {{(totalCount==0 ? 0 : ((currentPageNumber-1) * pageSize)+1)}} to {{(totalCount>
                              (currentPageNumber*pageSize))?(currentPageNumber*pageSize):(totalCount)}} of
                              {{(totalCount)}} records.
                      </td>
                      <td style="width:50%;text-align: right;">
                          <datatable-pager
                              [pagerLeftArrowIcon]="'datatable-icon-left'"
                              [pagerRightArrowIcon]="'datatable-icon-right'"
                              [pagerPreviousIcon]="'datatable-icon-prev'"
                              [pagerNextIcon]="'datatable-icon-skip'"
                              [page]="currentPageNumber"
                              [size]="pageSize"
                              [count]="totalCount"
                              [hidden]="!((totalCount / pageSize) > 1)"
                              (change)="onFooterPage($event)">
                          </datatable-pager>
                      </td>
                      <td style="width:20%;text-align: right;">
                          <form [formGroup]="formGroup" style="padding: 5px 10px">
                            Records per page                                    
                            <select formControlName="ddlPageSizeOptions" (change)="onPageSizeOptionChange($event)">                            
                              <option *ngFor="let item of  pageSizeOptions" [value]="item">
                                  {{item}}
                              </option>                            
                            </select> 
                          </form>
                      </td>
                  </tr>                    
              </table>
              </ng-template>
            </ngx-datatable-footer> 
          </ngx-datatable>
      </div>
    </div>
  </div>
  </div>
  </lte-layout-content>
  <lte-layout-footer [isUserLayout]="true">
    <lte-layout-footer-left>
      {{copyright}}
    </lte-layout-footer-left>
    <lte-layout-footer-right> </lte-layout-footer-right>
  </lte-layout-footer>
</lte-layout-wrapper>