import { Component, ViewEncapsulation } from '@angular/core';
import { LoadSpinnerService } from '../../core/services/load-spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadingSpinnerComponent {
  constructor(public loader: LoadSpinnerService) { }
}