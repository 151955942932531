    <div class="container">
      <div class="forbidden-section">
      <div class="login-box box box-default box-forbidden" >
          <div boxColor="primary" >
            <div class="login-logo-section justify-content-center pt-20">
              <span class="optum-logo">
                <img src="../../../../assets/img/optum.svg" class="" alt="Optum" width="100" />
              </span>
              <span class="PA-txt pl-3">Provider Attestation</span>
            </div>
            <div class="login-box-body">
              <div class="row login-box-content">
                <div class="text-center w-100"><i _ngcontent-ng-c72015181="" aria-hidden="true" class="fa fa-envelope icon-box"></i></div>
                <p class="w-100">{{message}} Please contact us at <br /> <a class="text-optum-color-dark" href="mailto:{{contactEmail}}?subject=Optum Provider Attestation web portal assistance">{{contactEmail}}</a> <br /> for more information.</p>
              </div>
            </div>
            <div class="footer d-flex login-box-content-center">
              <span>{{copyright}}</span>
            </div>
          </div>
      </div>
  </div>
</div>