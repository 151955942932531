import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, tap, throwError } from "rxjs";
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { IProviderAttestationUser, IProviderIdentity } from "../entities/user";

@Injectable({
    providedIn: 'root'
  })
  
  export class UserService {
    baseUrl!: string;
    providerList!:any;
    constructor(
      private http: HttpClient,
      private runtimeConfigLoaderService: RuntimeConfigLoaderService
    ) { 
      this.baseUrl = this.runtimeConfigLoaderService.getConfigObjectKey('API_URL');
    }

    saveUser(tokenParse:string):Observable<IProviderAttestationUser>{
      return this.http.post<any>(`${this.baseUrl}/user`,'', { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    } 
    
    getProviderNpi(tokenParse:string):Observable<IProviderIdentity[]>{
      return this.http.get<IProviderIdentity[]>(`${this.baseUrl}/user/providers`, { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }    
  }