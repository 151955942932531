import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, tap, throwError } from "rxjs";
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { IFormFieldModel } from '../../app-store/entities/formfields';
import { 
  IProviderAttestationModel,
  IProviderAttestationViewModel,
  IProviderBoard,
  IProviderInsurance,
   IProviderModel,
   IProviderOfficeInformationModel,
   IProviderSearchModel,
   IProviderSpecialtyModel, 
   IProviderViewModel
  } from "../../app-store/entities/provider";
import { IConstant } from "src/app/app-store/entities/constant";

@Injectable({
    providedIn: 'root'
  })
  
  export class ProviderAttestationService {
    baseUrl!: string;
    providerList!:any;
    constructor(
      private http: HttpClient,
      private runtimeConfigLoaderService: RuntimeConfigLoaderService
    ){ 

      this.baseUrl = this.runtimeConfigLoaderService.getConfigObjectKey('API_URL');
      //console.log(this.baseUrl);
    }

    getProviderAttestationDetails(searchParameterData:IProviderSearchModel):Observable<IProviderModel[]>{        
        let url:string='';
        url =`${this.baseUrl}/SB137ProviderAttestationDetails?AttestationPeriod=${searchParameterData.attestationPeriod}`;

        if(searchParameterData.providerName !='' && searchParameterData.providerName!=null )
          url= url + '&ProviderName=' + searchParameterData.providerName;

        if(searchParameterData.email !='' && searchParameterData.email!=null )
        url = url + '&Email=' + searchParameterData.npi;
      
        if(searchParameterData.npi !='' && searchParameterData.npi!=null )
          url = url + '&NPI=' + searchParameterData.npi;

        if(searchParameterData.tin !='' && searchParameterData.tin!=null )
          url = url + '&TIN=' + searchParameterData.tin;
        
        if(searchParameterData.isModified !=undefined && searchParameterData.isModified!=null )
          url = url + '&Modified=' + (searchParameterData.isModified);
        
        if(searchParameterData.isCompleted !=undefined && searchParameterData.isCompleted!=null )
        url = url + '&Completed=' + (searchParameterData.isCompleted);
        
        if(searchParameterData.isAttested !=undefined && searchParameterData.isAttested!=null )
        url = url + '&Attested=' + (searchParameterData.isAttested);
        
        if(searchParameterData.assignedTo !='' && searchParameterData.assignedTo!=null )
        url = url + '&AssignedTo=' + searchParameterData.assignedTo;
        
      
        url= url + `&PageNumber=${searchParameterData.pageNumber}&Pagesize=${searchParameterData.pageSize}&SortColumn=${searchParameterData.orderBy}&SortOrder=${searchParameterData.orderDescending=='asc'?'ASC':'DESC'}`;
        url= url.replaceAll('?&','?');
        //console.log(url);        
        return this.http.get<any>(url);         
    }

    getProviderDetailsProvider(providerId:number):Observable<IProviderViewModel>{
      return this.http.get<any>(`${this.baseUrl}/SB137ProviderDetail/GetSB137ProviderDetailsById/${providerId}`);      
    }

    getProviderByNPI(npi:string):Observable<IProviderModel>{
      return this.http.get<any>(`${this.baseUrl}/Provider/${npi}`);       
    }

    VerifyandRegisterUser(regCode:any, tin:string, npi:string, tokenParse:string):Observable<string>{
      return this.http.post<any>(`${this.baseUrl}/User?regCode=${regCode}&tin=${tin}&npi=${npi}`,"", { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }

    updateProvider(data:IProviderModel):Observable<IProviderModel>{
      return this.http.put<any>(`${this.baseUrl}/Provider`,data);       
    }

    updateProviderInsurance(data:IProviderInsurance):Observable<IProviderInsurance>{
      return this.http.put<any>(`${this.baseUrl}/Insurance`,data);       
    }

    addProviderInsurance(data:IProviderInsurance):Observable<IProviderInsurance>{
      return this.http.post<any>(`${this.baseUrl}/Insurance`,data);       
    }

    updateProviderBoard(data:IProviderBoard):Observable<IProviderBoard>{
      return this.http.put<any>(`${this.baseUrl}/Board`,data);       
    }

    addProviderBoard(data:IProviderBoard):Observable<IProviderBoard>{
      return this.http.post<any>(`${this.baseUrl}/Board`,data);       
    }

    updateProviderSpecialty(data:IProviderSpecialtyModel):Observable<IProviderSpecialtyModel>{
      return this.http.put<any>(`${this.baseUrl}/Specialty`,data);       
    }

    addProviderSpecialty(data:IProviderSpecialtyModel):Observable<IProviderSpecialtyModel>{
      return this.http.post<any>(`${this.baseUrl}/Specialty`,data);       
    }

    updateProviderLocation(data:IProviderOfficeInformationModel):Observable<IProviderOfficeInformationModel>{
      return this.http.put<any>(`${this.baseUrl}/Location`,data);       
    }

    addProviderLocation(data:IProviderOfficeInformationModel):Observable<IProviderOfficeInformationModel>{
      return this.http.post<any>(`${this.baseUrl}/Location`,data);       
    }

    getConstants():Observable<IConstant[]>{
      return this.http.get<any>(`${this.baseUrl}/Constants`);       
    }

    addProviderAttestation(data: IProviderAttestationModel):Observable<IProviderAttestationModel>{
      return this.http.post<any>(`${this.baseUrl}/Attestation`,data);
    }

    getProviderAttestationByProviderID(providerId: number):Observable<IProviderAttestationModel>{
      return this.http.get<any>(`${this.baseUrl}/Attestation?Id=${providerId}`)
    }
    getAssignToData(){
      return this.http.get<any>(`${this.baseUrl}/User/GetPdiUsers`);
    }
    getCurrentAttestationPeriod(period:string):Observable<any>{
      const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
      const requestOptions: Object = {
        headers: headers,
        responseType: 'text'
      }
      return this.http.get<any>(`${this.baseUrl}/Attestation/GetCurrentAttestationPeriod?dt=${period ?? ""}`,requestOptions);              
    }

    isCurrentAttestationPeriodClosed():Observable<any>{
      const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
      const requestOptions: Object = {
        headers: headers,
        responseType: 'text'
      }
      return this.http.get<any>(`${this.baseUrl}/AttestationPeriod/IsCurrentAttestationPeriodClosed`,requestOptions);              
    }

   
    getFormFields():IFormFieldModel[]{
      let providerFormFields: IFormFieldModel[] =[];
      
      providerFormFields=[
        //SECTION 1a - Provider Information
        {fieldName:'pi_birthdate',sectionId:'1a', dataKey:'dateOfBirth',fieldType:'date',isRequired:true,isRange:false},
        {fieldName:'ddlpi_gender',sectionId:'1a', dataKey:'gender',fieldType:'dropdown',isRequired:true,isRange:false},
        {fieldName:'pi_email',sectionId:'1a', dataKey:'personalEmail',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'pi_language',sectionId:'1a', dataKey:'languages',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pi_degree',sectionId:'1a', dataKey:'degree',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'pi_hospitalSurgeryCenterAffiliation',sectionId:'1a', dataKey:'hosSurgAff',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pi_ageMin',sectionId:'1a', dataKey:'ageRestrictions',fieldType:'text',isRequired:true,isRange:true},
        {fieldName:'pi_ageMax',sectionId:'1a', dataKey:'ageRestrictions',fieldType:'text',isRequired:true,isRange:true},
        {fieldName:'pi_chkPCP',sectionId:'1a', dataKey:'isPCP',fieldType:'checkbox',isRequired:false,isRange:false},
        {fieldName:'pi_chkSpecialty',sectionId:'1a', dataKey:'isSpecialist',fieldType:'checkbox',isRequired:false,isRange:false},
        {fieldName:'pi_officeemail',sectionId:'1a', dataKey:'primaryOfficeEmail',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'pi_organnizationalnpi',sectionId:'1a', dataKey:'organizationalNPI',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pi_emr',sectionId:'1a', dataKey:'emr',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pi_telehealth',sectionId:'1a', dataKey:'telehealth',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pi_chksdao',sectionId:'1a', dataKey:'isSdaOffered',fieldType:'checkbox',isRequired:false,isRange:false},
        
        //SECTION 1b - Provider License
        {fieldName:'pl_medicalnumber',sectionId:'1b', dataKey:'mediCalNum',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pl_medicarenumber',sectionId:'1b', dataKey:'medicareNum',fieldType:'text',isRequired:false,isRange:false},
       // {fieldName:'pl_upin',sectionId:'1b', dataKey:'upin',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pl_dea',sectionId:'1b', dataKey:'dea',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'pl_statelicense',sectionId:'1b', dataKey:'stateLicense',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'pl_statelicenseexpdate',sectionId:'1b', dataKey:'licenseExpiration',fieldType:'date',isRequired:true,isRange:false},

        //SECTION 2 - Board Training
        {fieldName:'pb_boardname',sectionId:'2', dataKey:'boardName',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'ddlpb_boardCertificationStatus',sectionId:'2', dataKey:'boardStatus',fieldType:'dropdown',isRequired:true,isRange:false},
        {fieldName:'pb_effectivedate',sectionId:'2', dataKey:'effectiveDate',fieldType:'date',isRequired:true,isRange:false},
        {fieldName:'pb_expirationdate',sectionId:'2', dataKey:'expirationDate',fieldType:'date',isRequired:true,isRange:false},

        //SECTION 3 - Provider Liability and MalPraction
        {fieldName:'pm_carriername',sectionId:'3', dataKey:'carrierName',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'pm_policynumber',sectionId:'3', dataKey:'policyNo',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'pm_expirationdate',sectionId:'3', dataKey:'expirationDate',fieldType:'date',isRequired:true,isRange:false},
        {fieldName:'pm_amountMin',sectionId:'3', dataKey:'amount',fieldType:'text',isRequired:true,isRange:true},
        {fieldName:'pm_amountMax',sectionId:'3', dataKey:'amount',fieldType:'text',isRequired:true,isRange:true},

        //SECTION 4 - Office Information
        {fieldName:'office_Id',sectionId:'4', dataKey:'locationId',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'ddloffice_Type',sectionId:'4', dataKey:'locationType',fieldType:'dropdown',isRequired:true,isRange:false},
        {fieldName:'office_Phone',sectionId:'4', dataKey:'phone',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'office_Fax',sectionId:'4', dataKey:'fax',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'office_Address1',sectionId:'4', dataKey:'streetAddress1',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'office_Address2',sectionId:'4', dataKey:'streetAddress2',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'office_City',sectionId:'4', dataKey:'city',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'office_State',sectionId:'4', dataKey:'state',fieldType:'text',isRequired:true,isRange:false},
        {fieldName:'office_Zip',sectionId:'4', dataKey:'zip',fieldType:'text',isRequired:true,isRange:false},               
        {fieldName:'office_ToHour',sectionId:'4', dataKey:'monHours',fieldType:'text',isRequired:false,isRange:true},              
        {fieldName:'office_FromHour',sectionId:'4', dataKey:'monHours',fieldType:'text',isRequired:false,isRange:true},
        {fieldName:'officeMonhrs',sectionId:'4', dataKey:'monHours',fieldType:'text',isRequired:true,isRange:false},        
        {fieldName:'officeTuehrs',sectionId:'4', dataKey:'tuesHours',fieldType:'text',isRequired:true,isRange:false},       
        {fieldName:'officeWedhrs',sectionId:'4', dataKey:'wedHours',fieldType:'text',isRequired:true,isRange:false},       
        {fieldName:'officeThuhrs',sectionId:'4', dataKey:'thursHours',fieldType:'text',isRequired:true,isRange:false},       
        {fieldName:'officeFrihrs',sectionId:'4', dataKey:'friHours',fieldType:'text',isRequired:true,isRange:false},        
        {fieldName:'officeSathrs',sectionId:'4', dataKey:'satHours',fieldType:'text',isRequired:true,isRange:false},        
        {fieldName:'officeSunhrs',sectionId:'4', dataKey:'sunHours',fieldType:'text',isRequired:true,isRange:false},      

        //SECTION 5 - Specialty
        {fieldName:'specialty_Id',sectionId:'5', dataKey:'specialtyId',fieldType:'text',isRequired:false,isRange:false},
        {fieldName:'specialty_ProviderSpecialty',sectionId:'5', dataKey:'speciality',fieldType:'text',isRequired:true,isRange:false}, 

        //SECTION 1C - CONTRACT INFORMATION
        {fieldName:'ddlpc_medicalEnrollmentStatus',sectionId:'1c', dataKey:'medicalStatus',fieldType:'dropdown',isRequired:true,isRange:false},
        {fieldName:'ddlpc_medicareEnrollmentStatus',sectionId:'1c', dataKey:'medicareStatus',fieldType:'dropdown',isRequired:true,isRange:false},
        {fieldName:'ddlpc_panelStatus',sectionId:'1c', dataKey:'panelStatus',fieldType:'dropdown',isRequired:true,isRange:false},
        {fieldName:'pc_credentialingDate',sectionId:'1c', dataKey:'credentialingDate',fieldType:'date',isRequired:false,isRange:false},
       
    ]
      return providerFormFields;
    }
    
  }