export const appConf = {
  skin: 'yellow',
  isSidebarLeftCollapsed: true,
  // isSidebarLeftExpandOnOver: true,
  // isSidebarLeftMouseOver: true,
  isSidebarLeftMini: true,
  // sidebarRightSkin: 'dark',
  // isSidebarRightCollapsed: true,
  // isSidebarRightOverContent: true,
  layout: 'normal',
  
  sidebarLeftMenu: [
    { name: 'MAIN NAVIGATION', separator: true },
    {
      name: 'Providers',
      iconClasses: 'fa fa-user-md',
      route: '/admin',
      isActive: true,
    },
    {
      name: 'reports',
      iconClasses: 'fa fa-bar-chart',
      route: '/report',
    },
    
  ],

}
