import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { IProviderAttestationModel, IProviderHistoryModel, INotificationStatus,INotificationStatusType, IProviderModel, IProviderStatus, IProviderSearchModel } from 'src/app/app-store/entities/provider';
import { ProviderAttestationService } from '../ProviderAttestation/provider-attestation.service';
import { DEFAULT_PAGE_SIZE } from 'src/app/core/constants';

@Injectable({
  providedIn: 'root'
})

export class ProviderHistoryService {
  baseUrl!: string;
  providerList!: any;
  defaultSearchParams : IProviderSearchModel =  {
    providerName: '',
      email:'',
      npi:'',
      tin:'',      
      pageNumber:1,
      pageSize:DEFAULT_PAGE_SIZE,
      orderBy:'name',
      orderDescending:'asc',
      isAttested: undefined,
      isCompleted: undefined,
	    assignedTo:'',
    	isModified:undefined,
      attestationPeriod: ""
  };
  searchParamsData = new BehaviorSubject<IProviderSearchModel>(this.defaultSearchParams);
  searchParms$ = this.searchParamsData.asObservable();

  constructor(
    private http: HttpClient,
    private runtimeConfigLoaderService: RuntimeConfigLoaderService
  ) {
    this.baseUrl =
      this.runtimeConfigLoaderService.getConfigObjectKey('API_URL');
    //console.log(this.baseUrl);
  }

  getProviderHistoryDetails(providerId: number): Observable<IProviderHistoryModel> {    
    return this.http.get<any>(
      `${this.baseUrl}/SB137ProviderDetail/GetSB137ProviderHistoryDetails/${providerId}`
    );
  }
  getproviderAttestationData(providerId:number){
    return this.http.get<any>(`${this.baseUrl}/Attestation?Id=${providerId}`)
   }

  getNotificationStatusTypes(){
    return this.http.get<any>(`${this.baseUrl}/NotificationStatus/GetAllNotificationStatusTypes`);
  }
  
  getAssignToData(){
    return this.http.get<any>(`${this.baseUrl}/User/GetPdiUsers`);
  }
  getNotificationStatusByProviderId(providerId:number){
    return this.http.get<any>(`${this.baseUrl}/NotificationStatus?providerId=${providerId}`);
  }
  saveNotificationStatus(data:INotificationStatus):Observable<INotificationStatus>{
    return this.http.post<any>(`${this.baseUrl}/NotificationStatus`,data);
  }
  updateNotificationStatus(data:INotificationStatus):Observable<INotificationStatus>{
    return this.http.put<any>(`${this.baseUrl}/NotificationStatus/UpdateNotificationStatus`,data);
  }
  updateProviderAttestaionUserId(providerId:number, userId:string, assignTo:string):Observable<IProviderModel>{
    return this.http.put<any>(`${this.baseUrl}/provider/updateprovideruserid/${providerId}/${userId}/${assignTo}`,"");
  }
  getAttestationLinkByProviderId(providerId:number):Observable<string>{
    var response =  this.http.get(`${this.baseUrl}/ScheduledNotification/AttestationLink?providerId=${providerId}`,{responseType: 'text'});
    return response;
  }
  getFaxPDFByProviderId(providerId:number, attestationPeriod:string){
    return this.http.get(`${this.baseUrl}/Document/GetFaxPDF?providerId=${providerId}&attestationPeriod=${attestationPeriod}&manual=1`,{responseType: 'blob'});
  }
  saveProviderNotificationStatus(data:IProviderStatus):Observable<INotificationStatus>{
    return this.http.post<any>(`${this.baseUrl}/ProviderStatus`,data);
  }
  getProviderDataByProviderId(providerId:number){
    return this.http.get<any>(`${this.baseUrl}/ProviderStatus?id=${providerId}`);
  }
  updateProviderNotificationStatus(data:IProviderStatus):Observable<INotificationStatus>{
    return this.http.put<any>(`${this.baseUrl}/ProviderStatus`,data);
  }
  setSearchParms(parms: IProviderSearchModel){
    this.searchParamsData.next(parms);
  }
  clearSearchParams(){
    this.searchParamsData.next(this.defaultSearchParams);
  }
}
