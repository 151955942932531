<header #headerElement class="main-header">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-6 col-sm-4">
        <div class="logo-content">
        
          <a  class="logo-section">
            <span class="optum-logo pt-0">      
              <img src="../../../../assets/img/optum.svg" alt="Optum" width="100" />
            </span>
            
            <span class="PA-txt pt-0">{{headerTitle}}</span>
          </a>
        </div>
      </div>
      <div class="col-6 col-sm-8">
        <div class="user-section">
          <table>
            <tr>
              <td *ngIf="isAdminLayoutType">
                <a href="#" [routerLink]="['/admin']" class="user d-flex align-items-center">
                  <span class="user d-flex align-items-center"> <i class="fa fa-users px-2" aria-hidden="true"></i> Providers </span>  
                </a>              
              </td>
              <td *ngIf="isAdminLayoutType">
                <a href="#" [routerLink]="['/report']" class="user d-flex align-items-center">
                  <span class="user d-flex align-items-center"> <i class="fa fa-file-text-o px-2" aria-hidden="true"></i> Reports </span>  
                </a>             
              </td>
              <td *ngIf="isAdminLayoutType"><span class="user d-flex align-items-center"> | </span></td>
              <td><span class="user d-flex align-items-center"> <i class="fa fa-user px-2"></i> {{ userFullName }} </span> </td>
              <td>
                <a (click)="logout()" class="signout d-flex align-items-center">
                  <i class="fa fa-power-off px-2" aria-hidden="true"></i> Logout
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    </div>
  </header>