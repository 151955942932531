import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, tap, throwError } from "rxjs";
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { IFormFieldModel } from '../../app-store/entities/formfields';
import { IAttestationPeriod } from '../../app-store/entities/attestationPeriod';
import { IAttestationReportData, IAttestationReportModel, IAttestationReporFiltertModel } from '../../app-store/entities/attestationReport';

@Injectable({
  providedIn: 'root'
})

export class PortalAttestationService {

    baseUrl!: string;

    constructor(
      private http: HttpClient,
      private runtimeConfigLoaderService: RuntimeConfigLoaderService
    ){ 

      this.baseUrl = this.runtimeConfigLoaderService.getConfigObjectKey('API_URL');
      //console.log(this.baseUrl);
    }

    getAttestationPeriod(): Observable<IAttestationPeriod[]>{
      return this.http.get<IAttestationPeriod[]>(`${this.baseUrl}/AttestationPeriod`)
      .pipe(
        tap(data => console.log('All: ' + JSON.stringify(data))),
        //catchError(this.handleError)
      );
    }

    getReportDataCount(attestationPeriod: string): Observable<IAttestationReportData[]>{
        return this.http.get<IAttestationReportData[]>(`${this.baseUrl}/AttestationReport/GetAttestationReportCount?AttestationPeriod=${attestationPeriod}`)
        .pipe(
          tap(data => console.log('All: ' + JSON.stringify(data))),
          //catchError(this.handleError)
        );
    }

    getAttestationReportDetails(searchFilterData: IAttestationReporFiltertModel):Observable<IAttestationReportModel[]> {
      let url:string='';
      url =`${this.baseUrl}/AttestationReport/GetAttestationReportList?AttestationPeriod=${searchFilterData.attestationPeriod}`;
      if(searchFilterData.hasException !=undefined && searchFilterData.hasException!=null )
        url = url + '&hasException=' + searchFilterData.hasException;
      if(searchFilterData.isAttested !=undefined && searchFilterData.isAttested!=null )
        url = url + '&isAttested=' + searchFilterData.isAttested;
      if(searchFilterData.attempt !=undefined && searchFilterData.attempt!=null )
        url = url + '&attempt=' + searchFilterData.attempt;
      if(searchFilterData.failure !=undefined && searchFilterData.failure!=null ) 
        url = url + '&failure=' + searchFilterData.failure; 
      if(searchFilterData.activeOutbound !=undefined && searchFilterData.activeOutbound!=null ) 
        url = url + '&activeOutbound=' + searchFilterData.activeOutbound;         
      if(searchFilterData.pageNumber !=undefined && searchFilterData.pageNumber!=null )
        url= url + '&pageNumber=' + searchFilterData.pageNumber;
      if(searchFilterData.pageSize !=undefined && searchFilterData.pageSize!=null )
        url= url + '&pagesize=' + searchFilterData.pageSize;
      if(searchFilterData.sortColumn !=undefined && searchFilterData.sortColumn!=null )
        url= url + '&sortColumn=' + searchFilterData.sortColumn;
      if(searchFilterData.sortOrder !=undefined && searchFilterData.sortOrder!=null )
        url= url + '&sortOrder=' + (searchFilterData.sortOrder=='asc'?'ASC':'DESC');
      url= url.replaceAll('?&','?');
      //console.log(url);        
      return this.http.get<any>(url);  
    }    

    downloadAttestationReportDetails(searchFilterData: IAttestationReporFiltertModel){
      let url:string='';
      url =`${this.baseUrl}/AttestationReport/GetAttestationReportExporttoXls?AttestationPeriod=${searchFilterData.attestationPeriod}`;
      if(searchFilterData.hasException !=undefined && searchFilterData.hasException!=null )
        url = url + '&hasException=' + searchFilterData.hasException;
      if(searchFilterData.isAttested !=undefined && searchFilterData.isAttested!=null )
        url = url + '&isAttested=' + searchFilterData.isAttested;
      if(searchFilterData.attempt !=undefined && searchFilterData.attempt!=null )
        url = url + '&attempt=' + searchFilterData.attempt;
      if(searchFilterData.failure !=undefined && searchFilterData.failure!=null ) 
        url = url + '&failure=' + searchFilterData.failure;     
      if(searchFilterData.activeOutbound !=undefined && searchFilterData.activeOutbound!=null ) 
        url = url + '&activeOutbound=' + searchFilterData.activeOutbound;     
      if(searchFilterData.pageNumber !=undefined && searchFilterData.pageNumber!=null )
        url= url + '&pageNumber=' + searchFilterData.pageNumber;
      if(searchFilterData.pageSize !=undefined && searchFilterData.pageSize!=null )
        url= url + '&pagesize=' + searchFilterData.pageSize;
      if(searchFilterData.sortColumn !=undefined && searchFilterData.sortColumn!=null )
        url= url + '&sortColumn=' + searchFilterData.sortColumn;
      if(searchFilterData.sortOrder !=undefined && searchFilterData.sortOrder!=null )
        url= url + '&sortOrder=' + (searchFilterData.sortOrder=='asc'?'ASC':'DESC');
      url= url.replaceAll('?&','?');
      //console.log(url);        
      return this.http.get<any>(url, {responseType: 'blob' as 'json'});  
    }        
}
