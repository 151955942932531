import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ContentChild,
  ElementRef,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'modal-header',
  template: `<ng-template #templateRef><ng-content></ng-content></ng-template> `,
})
export class ModalHeader {
  @ViewChild('templateRef', { static: true })
  public templateRef!: TemplateRef<any>;
}

@Component({
  selector: 'modal-body',
  template: `<ng-template #templateRef><ng-content></ng-content></ng-template> `,
})
export class ModalBody {
  @ViewChild('templateRef', { static: true })
  public templateRef!: TemplateRef<any>;
}

@Component({
  selector: 'modal-footer',
  template: `<ng-template #templateRef><ng-content></ng-content></ng-template> `,
})
export class ModalFooter {
  @ViewChild('templateRef', { static: true })
  public templateRef!: TemplateRef<any>;
}

@Component({
  selector: 'lte-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  private _clicked: Subject<any> = new Subject<any>();

  @ContentChild(ModalHeader, { static: true })
  public modalHeader?: ModalHeader;

  @ContentChild(ModalBody, { static: true })
  public modalBody?: ModalBody;

  @ContentChild(ModalFooter, { static: true })
  public modalFooter?: ModalFooter;

  @ViewChild('modalRoot')
  public modalRoot!: ElementRef;

  public isOpened = false;

  // -------------------------------------------------------------------------
  // Inputs
  // -------------------------------------------------------------------------

  @Input()
  public contentWidth!: string;

  @Input()
  public isFormModal!: boolean;

  @Input()
  public modalClass!: string;

  @Input()
  public closeOnEscape: boolean = true;

  @Input()
  public closeOnOutsideClick: boolean = true;

  @Input()
  public title!: string;

  @Input()
  public displayReviewButton: boolean = false;

  @Input()
  public displaySubmitButton: boolean = true;

  @Input()
  public displayBackButton: boolean = false;

  @Input()
  public hideCloseButton = false;

  @Input()
  public cancelButtonLabel!: string;

  @Input()
  public submitButtonLabel!: string;

  @Input()
  public reviewButtonLabel!: string;

  @Input()
  public backButtonLabel!: string;

  @Input()
  public disableSubmitButton = false;

  @Input()
  public disableReviewButton = false;

  @Input()
  public valid = true;

  @Input()
  public footerText!: string; 

  subscription!: Subscription;

  @Input()
  public buttonAlign!: string;

  // -------------------------------------------------------------------------
  // Outputs
  // -------------------------------------------------------------------------

  @Output()
  public onOpen = new EventEmitter(false);

  @Output()
  public onClose = new EventEmitter(false);

  @Output()
  public onSubmit = new EventEmitter(false);

  @Output()
  public onConfirm = new EventEmitter(false);

  @Output()
  public onBack = new EventEmitter(false);

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  open(...args: any[]): Observable<any> {
    if (this.isOpened) return of('');
    this.renderer.addClass(this.modalRoot.nativeElement, 'modal-open');
    setTimeout(() => {
      this.renderer.addClass(this.modalRoot.nativeElement, 'in');
    }, 150);

    this.isOpened = true;
    this.onOpen.emit(args);
    return this._clicked;
  }

  review(...args: any[]): void {
    if (this.valid) {
      this.onConfirm.emit(args);
      this._clicked.next('review');
    }
  }

  submit(...args: any[]): void {
    if (this.valid) {
      this.closeModel();
      this.onSubmit.emit(args);
      this._clicked.next('submit');
    }
  }

  close(...args: any[]): void {
    if (!this.isOpened) return;
    this.closeModel();
    this.onClose.emit(args);
  }

  back(...args: any[]): void {
    this.onBack.emit(args);
    this._clicked.next('back');
  }

  ngOnDestroy() {
    this.closeModel();
  }

  closeModel() {
    this.isOpened = false;
    this.renderer.removeClass(this.modalRoot.nativeElement, 'in');
    setTimeout(() => {
      this.isOpened = false;
      this.renderer.removeClass(this.modalRoot.nativeElement, 'modal-open');
      this._clicked.next('close');
    }, 150);
  }

  public preventClosing(event: MouseEvent) {
    event.stopPropagation();
  }
}
