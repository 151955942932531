import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';

export const authPdiUserGuard: CanActivateFn = (route, state) => {
  return inject(AuthService).isPdiUserRole();
};

export const authPdiAdminGuard: CanActivateFn = (route, state) => {
  return inject(AuthService).isPdiAdminRole();
};

export const authUserGuard: CanActivateFn = (route, state) => {
  return inject(AuthService).isAuthenticatedUser();
};


