import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ModalComponent,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from './modal.component';

const components: any[] = [ModalComponent, ModalFooter, ModalHeader, ModalBody];

@NgModule({
  declarations: [...components],
  imports: [CommonModule],
  exports: [...components],
})
export class ModalModule {}
