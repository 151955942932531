export class CommonUtil {
    static convertTime(time?: string) {
        let retValue: string = '';
        if (time != null && time != undefined) {
            if (time !== '' && time.includes('-')) {
                retValue = this.standardTimeFormat(this.getParseData(time, true)) + '-' + this.standardTimeFormat(this.getParseData(time, false));
            }
            else if (time == 'Closed')
                retValue = time;
        }
        //console.log(retValue);
        return retValue;
    }

    static standardTimeFormat(time?: string) {
        let retValue: string = '';
        if (time != null && time != undefined) {
            if (time !== '' && time.includes(':') && (!time.includes('undefined'))) {
                var dtParts = time.split(":");

                var hours = parseInt(dtParts[0]);
                var minutes = parseInt(dtParts[1]);
                var suffix = "AM";

                if (hours > 12) {
                    hours = hours - 12;
                    suffix = "PM";
                }
                else if (String(hours) == "00") {
                    hours = 12;
                    suffix = "AM";
                }
                else if (String(hours) == "12") {
                    suffix = "PM";
                }
                retValue = ((String(hours).length == 1) ? '0' + String(hours) : String(hours)) + ":" + ((String(minutes).length == 1) ? '0' + String(minutes) : String(minutes)) + suffix;
            }
        }
        return retValue;
    }

    static getParseData(data: any, isMinValue: boolean) {
        let parsedData: any;
        let retValue: string = ''
        if (data != null && data != undefined) {
            if (data != '') {
                if (data.includes('-')) {
                    parsedData = data.split('-');
                    if (parsedData != null && parsedData != undefined) {
                        if (parsedData.length > 0 && isMinValue) {
                            retValue = parsedData[0];
                        }
                        else
                            retValue = parsedData[1];
                    }
                }
                else if (data.toLowerCase() == 'closed') {
                    retValue = data;
                }
            }
        }
        return retValue;
    }
    static parseDate(dt: string) {
        if (dt != null && dt != undefined) {
            if (dt != '') {
                if (!dt.includes('/')) {
                    dt = dt.substring(0, 2) + '/' + dt.substring(2, 4) + '/' + dt.substring(4, dt.length);
                }
            }
        }
        return dt;
    }
}