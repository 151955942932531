export const PAGE_SIZE_OPTIONS = [10, 15, 20, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = 10;
export const DOWNLOAD_PAGE_SIZE = 20000;
export const MSG_PROVIDER_INFORMATION_LOAD = 'Provider information loaded successfully';
export const MSG_PROVIDER_INFORMATION_LOAD_ERROR = 'Provider information load failed, please try again';

export const MSG_PROVIDER_INFORMATION_UPDATE = 'Provider information updated successfully';
export const MSG_PROVIDER_INFORMATION_UPDATE_ERROR = 'Unable to update provider information, please try again';

export const MSG_LICENSE_INFORMATION_UPDATE = 'Provider license information updated successfully';
export const MSG_LICENSE_INFORMATION_UPDATE_ERROR = 'Unable to update provider license information, please try again';

export const MSG_CONTRACT_INFORMATION_UPDATE = 'Provider contract information updated successfully';
export const MSG_CONTRACT_INFORMATION_UPDATE_ERROR = 'Unable to update provider contract information, please try again';

export const MSG_PROVIDER_BOARD_UPDATE = 'Provider board information updated successfully';
export const MSG_PROVIDER_BOARD_UPDATE_ERROR = 'Unable to update provider board information, please try again';
export const MSG_PROVIDER_BOARD_ADD = 'Provider board training added successfully';
export const MSG_PROVIDER_BOARD_ADD_ERROR = 'Unable to add provider board training, please try again';

export const MSG_PROVIDER_INSURANCE_UPDATE = 'Provider insurance information updated successfully';
export const MSG_PROVIDER_INSURANCE_UPDATE_ERROR = 'Unable to update provider insurance information, please try again';
export const MSG_PROVIDER_INSURANCE_ADD = 'Provider insurance information added successfully';
export const MSG_PROVIDER_INSURANCE_ADD_ERROR = 'Unable to add provider insurance information, please try again';

export const MSG_PROVIDER_SPECIALTY_ADD = 'Provider specialty added successfully';
export const MSG_PROVIDER_SPECIALTY_ADD_ERROR = 'Unable to add provider specialty, please try again';
export const MSG_PROVIDER_SPECIALTY_UPDATE = 'Provider specialty updated successfully';
export const MSG_PROVIDER_SPECIALTY_UPDATE_ERROR = 'Unable to update provider specialty, please try again';
export const MSG_PROVIDER_SPECIALTY_DELETE = 'Provider specialty deleted successfully';
export const MSG_PROVIDER_SPECIALTY_DELETE_ERROR = 'Unable to delete provider specialty, please try again';

export const MSG_PROVIDER_OFFICE_INFORMATION_ADD = 'Provider office information added successfully';
export const MSG_PROVIDER_OFFICE_INFORMATION_ADD_ERROR = 'Unable to add provider office information, please try again';
export const MSG_PROVIDER_OFFICE_INFORMATION_UPDATE = 'Provider office information updated successfully';
export const MSG_PROVIDER_OFFICE_INFORMATION_UPDATE_ERROR = 'Unable to update provider office information, please try again';
export const MSG_PROVIDER_OFFICE_INFORMATION_DELETE = 'Provider office information deleted successfully';
export const MSG_PROVIDER_OFFICE_INFORMATION_DELETE_ERROR = 'Unable to delete provider office information, please try again';

export const MSG_PROVIDER_ATTESTATION_ADD = 'Provider data attested successfully';
export const MSG_PROVIDER_ATTESTATION_ADD_ERROR = 'Unable to attest provider data, please try again';

export const MSG_NOTIFICATION_ADD = 'Provider status added successfully';
export const MSG_NOTIFICATION_ADD_ERROR = 'Unable to add Provider status, please try again';
export const MSG_NOTIFICATION_UPDATE = 'Provider status updated successfully';
export const MSG_NOTIFICATION_UPDATE_ERROR = 'Unable to update Provider status, please try again';