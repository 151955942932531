
<router-outlet></router-outlet>
<div class="row">
  <div class="col-md-10 plr-0">
    <app-spinner></app-spinner>
    <!-- <app-login></app-login> -->
    <!-- <app-provider-attestation></app-provider-attestation> -->
  <!-- <app-admin-layout></app-admin-layout>  -->
  </div>
</div>

<lte-modal
  #sessionTimeoutModal
  submitButtonLabel="Stay Active"
  cancelButtonLabel="Logout"
  contentWidth="420px"
  (onSubmit)="timeoutWarningSubmit()"
  (onClose)="logoutSession()"
  [hideCloseButton]="true"
  buttonAlign="textCenter"
>
  <modal-header class="border-bottom-0" style="border-bottom:0px;">
    <!-- <b><i class="fa fa-clock-o"></i> Your session is about to expire!</b> -->
  </modal-header>
  <modal-body>
    <div style="text-align:center">
      <p><i class="fa fa-clock-o fa-2x optum-orange"></i> </p>
      <h5>Your session is about to expire!</h5>
    
      <p>{{ timeoutMessage }}
      <h5>{{ timeoutRemainingTime }} </h5>
    </div>
  </modal-body>
  <modal-footer class="alignCenter"></modal-footer>
</lte-modal>
