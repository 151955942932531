import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';

@Component({
  selector: 'liveness',
  templateUrl: './liveness.component.html',
  styleUrls: ['./liveness.component.scss']
})

export class LivenessComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }

  status: String = '';

  ngOnInit() {
    const route: String = this.activatedRoute.snapshot.url.join('');
    if (route === 'liveness')
      this.status = 'Ready';
    if (route === 'hc')
      this.status = 'Ready';
  }
}