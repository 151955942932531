import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Router,RouterModule,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { appConf } from './app.config';
import { LayoutModule } from './libs/layout.module';
import { ProviderAttestationModule } from './views/ProviderAttestation/provider-attestation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable'; 
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ProviderAttestationComponent } from './views/ProviderAttestation/provider-attestation.component';
import { ProviderListComponent } from './views/providers/provider-list.component';
import {LoginComponent} from './views/login/login.component';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { UserLayoutComponent } from './shared/layouts/user-layout/user-layout.component';
import { ProviderAdminLayoutComponent } from './shared/layouts/provider-admin-layout/provider-admin-layout.component';
import {AddOfficeHourComponent} from './views/ProviderAttestation/Modals/add-office-hours/add-Office-hour.component';
import {ProviderHistoryComponent} from './views/providers/provider-history.component';
import {ProviderReportComponent} from './views/report/provider-report.component';
import {LoadingSpinnerComponent} from './shared/loading-spinner/loading-spinner.component';
import {LoadingSpinnerInterceptor} from './core/interceptor/loading-spinner.interceptor';
import { APP_BASE_HREF,DatePipe,Location } from '@angular/common';
import { initConfig, RuntimeConfigLoaderModule, RuntimeConfigLoaderService } from 'runtime-config-loader';
import { CurrentStorageService } from './core/services/current-storage.service';
import { IConfig, provideEnvironmentNgxMask,NgxMaskPipe,NgxMaskDirective } from 'ngx-mask';
import { LivenessComponent } from './views/liveness/liveness.component';
import { CommonUtil } from './shared/Util/common-util';
import { AuthConfigModule } from './auth/auth-config.module';
import { SelectComponent } from './views/select/select.component';
import { RegistrationComponent } from './views/registration/registration.component';
import { ForbiddenComponent } from './views/forbidden/forbidden.component';
import { CallbackComponent } from './views/callback/callback.component';
import { FormatPhonePipe } from './shared/Pipes/format-phone.pipe';
import { LogoutComponent } from './views/logout/logout.component';
import { FooterComponent } from './views/footer/footer.component';
import {NgIdleModule} from '@ng-idle/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventBindingDirective } from './shared/Util/keyboard-event';
const DEFAULT_MASK_CONFIG: Partial<IConfig> = {
  clearIfNotMatch: false,
  decimalMarker: '.'
};

@NgModule({ declarations: [
        AppComponent,
        FormatPhonePipe,
        ProviderAttestationComponent,
        LoginComponent,
        AdminLayoutComponent,
        UserLayoutComponent,
        ProviderAdminLayoutComponent,
        ProviderListComponent,
        AddOfficeHourComponent,
        ProviderHistoryComponent,
        LoadingSpinnerComponent,
        ProviderReportComponent,
        LivenessComponent,
        SelectComponent,
        RegistrationComponent,
        ForbiddenComponent,
        CallbackComponent,
        FooterComponent,
        LogoutComponent,
        EventBindingDirective
    ],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserModule,
        SharedModule,
        LayoutModule.forRoot(appConf),
        ProviderAttestationModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot([]),
        RuntimeConfigLoaderModule.forRoot({ configUrl: './assets/config.json' }),
        NgxDatatableModule,
        NgxMaskPipe,
        NgxMaskDirective,
        AuthConfigModule,
        NgIdleModule.forRoot(),
        NgbModule], providers: [
        { provide: APP_BASE_HREF, useValue: window[0] || '/' },
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [RuntimeConfigLoaderService],
            multi: true,
        },
        CurrentStorageService,
        provideEnvironmentNgxMask(DEFAULT_MASK_CONFIG),
        {
            provide: HTTP_INTERCEPTORS, useClass: LoadingSpinnerInterceptor, multi: true
        },
        CommonUtil,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { 
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
   
    router.events.subscribe((e) => {
        if (e instanceof NavigationEnd){
          var title = 'Provider Attestation';
          if (this.route.snapshot.firstChild?.data['title'] !=undefined){
            title = title + ' | ' + this.route.snapshot.firstChild?.data['title'];  
          }          
          this.titleService.setTitle(title);
        }
    })

  }
}
