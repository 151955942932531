<div class="content-wrapper" [style.min-height.px]="heightStyle < 500 ? '768' : heightStyle" [class.test] = "isAdminLayoutType">
  <div #contentInnerElement class="content-inner" >
    <ng-content select="[lte-layout-content-before-header]"></ng-content>
    <section *ngIf="header || description" class="content-header">
      <h1>
        {{ header }}
        <small *ngIf="description">{{ description }}</small>
      </h1>     
    </section>
    <ng-content select="[lte-layout-content-after-header]"></ng-content>
    <section class="content">
      <ng-content></ng-content>
    </section>
  </div>
</div>
