    
    <div class="container mt-5">
    <div class="row  p-t-0 m-t-0">
      <div class="col-md-12 m-b-10">
        <div class="alert alert-success" *ngIf="isAttesteationCompleted && isReadOnly">
          <span *ngIf="providerAttestationData$!">The attestation process has been completed as of {{this.providerAttestationData$.attestedDate| date: 'MM/dd/yyyy'}}</span> 
        </div>
        <div class="alert alert-danger" *ngIf="!isAttesteationCompleted && isReadOnly">
          The attestation process is not completed.
        </div>
        <div class="alert alert-danger" *ngIf="attestationErrorMessage!=''">
          Attestation failed due to following reason(s) -
          <span [innerHTML]="attestationErrorMessage"></span>          
        </div>
      </div>
    </div>
    <!--Header section-->
    <div style="margin-top: 5px !important;margin-bottom: 15px !important;">
      <div class="row" *ngIf="isLoading">
        <div class="col-md-12">
          <div class="card">
            <div class="card-title"></div>
            <div class="card-body py-0"> 
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-8">
                  <div class="d-flex align-items-center">
                <div class="form-group">
                  <div class="circle ml-0"> <span class="card-text"> <i class="fa fa-user-md fa-2x" aria-hidden="true"></i></span></div>
                </div>
                <div class="form-group ml-2" *ngIf="providerData$!">
                  <label style="margin-top:5px">{{providerData$.providerDetails.firstName}} {{providerData$.providerDetails.middleName}} {{providerData$.providerDetails.lastName}}, {{providerData$.providerDetails.degree}}</label>
                    <div class="container" style="margin-top: -10px;">
                      <div class="row" >
                        <div class="form-group col-md-12 p-0 m-0">
                        <small><b>NPI:</b></small><small>{{providerData$.providerDetails.npi}}</small>&nbsp;&nbsp;&nbsp;&nbsp; <small><b>TIN:</b></small><small>{{providerData$.providerDetails.tin}}</small>
                        </div>                
                      </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group" style="text-align: right;color: #030d68;">
                  <label style="margin-top:15px;margin-right: 25px;" *ngIf="this.providerData$!">{{attestionPeriod}}</label>
                </div>
                </div>
              </div>           
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--SECTION 1a - Provider Information -->                
    <div style="margin-top: 15px !important;margin-bottom: -15px !important;">
      <div class="row" *ngIf="isLoading" >        
        <div class="form-group col-md-12">
          <div class="card">
            <div class="card-title borderstyle">
              <span class="headerTitle">Provider Information</span>
              <button class="btn btn-secondary-optum rounded-pill mr-3 pl-4 pr-4 pull-right btn-custom-bottom" *ngIf="!isReadOnly && !isEditable" (click)="editProviderInformation()">Edit</button>
              <button class="btn btn-secondary-optum rounded-pill mr-3 pull-right btn-custom-bottom" *ngIf="!isReadOnly && isEditable" (click)="cancelProviderInformation()">Cancel</button>
              <button class="btn btn-primary rounded-pill mr-2 pl-4 pr-4 pull-right btn-custom-bottom" *ngIf="!isReadOnly && isEditable" (click)="saveProviderInformation('1a')">Save</button>
            </div>
            <div class="card-body pt-1 pb-0 pl-1 pr-1">
              <form [formGroup]="formGroup">
                <div class="row mt-0 mb-0 ml-1 mr-1">                        
                  <div class="form-group col-md-3 col-xs-12" >
                    <label>Birth Date <span class="text-red">*</span></label>
                      <input maxlength="10" type="text" class="form-control"  mask="M0/d0/0000" [clearIfNotMatch]="true" placeholder="mm/dd/yyyy"  formControlName="pi_birthdate" readonly /> 
                      <small *ngIf="formGroup.controls['pi_birthdate'].hasError('empty') && formGroup.controls['pi_birthdate'].touched" class="help-block" >Birth Date is required</small>                           
                  </div>
                  <div class="form-group col-md-3 col-xs-12 " >
                    <label>Gender <span class="text-red">*</span></label>
                  <select class="form-control" formControlName="ddlpi_gender" readonly>
                      <option value=""></option>
                      <option *ngFor="let item of gender" [value]="item.constantValue">
                        {{item.constantDesc}}
                      </option>
                  </select> 
                  <small *ngIf="formGroup.controls['ddlpi_gender'].hasError('empty') && formGroup.controls['ddlpi_gender'].touched" class="help-block" >Gender is required</small>
                  </div>                  
                  <div class="form-group col-md-3 col-xs-12 ">
                    <label>Provider Email <span class="text-red">*</span></label>
                      <input maxlength="50" type="text" class="form-control"  placeholder="example@domain.com" formControlName="pi_email" />                            
                      <small *ngIf="formGroup.controls['pi_email'].hasError('empty') && formGroup.controls['pi_email'].touched" class="help-block" >Provider Email is required</small>
                      <small *ngIf="formGroup.controls['pi_email'].hasError('pattern') && formGroup.controls['pi_email'].touched" class="help-block" >Invalid Provider Email.</small>
                  </div>
                  <div class="form-group col-md-3 col-xs-12 ">
                    <label>Office Email <span class="text-red">*</span></label>
                      <input maxlength="50" type="text" class="form-control"  placeholder="example@domain.com"  formControlName="pi_officeemail"/>                            
                      <small *ngIf="formGroup.controls['pi_officeemail'].hasError('empty') && formGroup.controls['pi_officeemail'].touched" class="help-block" >Office Email is required</small>
                      <small *ngIf="formGroup.controls['pi_officeemail'].hasError('pattern') && formGroup.controls['pi_officeemail'].touched" class="help-block" >Invalid Office Email.</small>
                  </div>
                </div>                                      
                <div class="row mt-0 mb-0 ml-1 mr-1">
                  <div class="form-group col-md-3 col-xs-12 ">
                    <label>Organizational NPI</label>
                      <input maxlength="50" type="text" class="form-control"  placeholder="Organizational NPI"  formControlName="pi_organnizationalnpi" (keypress)="keyPressValidation($event,'D')"/>                                                  
                  </div>
                  <div class="form-group col-md-3 col-xs-12 ">
                    <label>EMR</label>
                      <input maxlength="10" type="text" class="form-control"  placeholder="EMR"  formControlName="pi_emr" (keypress)="keyPressValidation($event,'A')"/>                      
                  </div>
                  <div class="form-group col-md-3 col-xs-12 " >
                    <label>Telehealth</label>
                      <input maxlength="10" type="text" class="form-control"  placeholder="Telehealth"  formControlName="pi_telehealth" (keypress)="keyPressValidation($event,'A')"/>                      
                  </div>
                  <div class="form-group col-md-3 col-xs-12 ">
                    <label>Degree(s) <span class="text-red">*</span><small>(Comma separated)</small></label>
                      <input maxlength="10" type="text" class="form-control"  placeholder="Degree"  formControlName="pi_degree" readonly (keypress)="keyPressValidation($event,'B')"/>
                      <small *ngIf="formGroup.controls['pi_degree'].hasError('empty') && formGroup.controls['pi_degree'].touched" class="help-block">Degree is required</small>                            
                  </div>                                                      
                </div>
                <div class="row mt-0 mb-0 ml-1 mr-1">
                  <div class="form-group col-md-3 col-xs-12 ">
                    <label>Language(s) <small>(Comma separated)</small></label>
                    <textarea maxlength="500" rows="2" cols="20" name="text" placeholder="Languages" class="form-control" formControlName="pi_language" alphabetWithSpaceCommaOnly (keypress)="keyPressValidation($event,'B')"></textarea>                    
                  </div>                         
                  <div class="form-group col-md-3 col-xs-12 ">
                    <label>Hospital Surgery Center Affiliation</label>
                    <textarea maxlength="500" rows="2" cols="20" name="text" placeholder="Hospital Surgery Center Affiliation" class="form-control" formControlName="pi_hospitalSurgeryCenterAffiliation" (keypress)="keyPressValidation($event,'B')"></textarea>                     
                  </div>
                  <div class="form-group col-md-3 col-xs-12 " >
                    <label>Age Restrictions <span class="text-red">*</span></label>
                    <div class="container">
                      <div class="row" >
                        <div class="col-sm pl-0">
                          <input maxlength="3" type="text" class="form-control"  mask="0*"  placeholder="Min" formControlName="pi_ageMin" (input)="validateAgeRestriction($event.target)"/>                                                                                  
                          <small *ngIf="formGroup.controls['pi_ageMin'].hasError('empty') && formGroup.controls['pi_ageMin'].touched" class="help-block">Min age is required</small>                          
                        </div>
                        <span>-</span>
                        <div class="col-sm ">
                          <input maxlength="3" type="text" class="form-control"  mask="0*" placeholder="Max" formControlName="pi_ageMax" (input)="validateAgeRestriction($event.target)"/>               
                          <small *ngIf="formGroup.controls['pi_ageMax'].hasError('empty') && formGroup.controls['pi_ageMax'].touched" class="help-block">Max age is required</small>                           
                        </div>
                      </div>
                      <div class="row">
                          <small *ngIf="!isValidAgeRange" class="help-block">Invalid Age Range.</small>
                          <small *ngIf="(formGroup.controls['pi_ageMax'].value > 100)" class="help-block">Max age should be less than 100.</small>
                          <small *ngIf="(formGroup.controls['pi_ageMin'].value > 100)" class="help-block">Min age should be less than 100.</small> 
                       
                      </div>
                      
                    </div>
                  </div>
                  <div class="form-group col-md-3 col-xs-12" style="padding-top: 30px;">
                    <div class="card" [ngStyle]="{'background-color': !isEditable ? '#e9ecef' : '#ffffff'}" >
                      <div class="card-body" style="padding: 0px !important; padding-left: 20px !important; ">
                        <div class="row" >
                          <div class="col-sm pl-0">
                            <input type="checkbox" name="pi_chkPCP" id="pi_chkPCP" (change)="checkPCP($event)" formControlName="pi_chkPCP"/><span>&nbsp;&nbsp;&nbsp;PCP</span><span class="text-red" *ngIf="formGroup.controls['pi_chkSpecialty'].value =='' && formGroup.controls['pi_chkPCP'].value ==''"> *</span>
                            <small *ngIf="formGroup.controls['pi_chkSpecialty'].value =='' && formGroup.controls['pi_chkPCP'].value =='' && formGroup.controls['pi_chkPCP'].touched && this.isEditable" class="help-block pl-2" >PCP or Specialist is required</small>                           
                          </div>
                        </div>
                        <div class="row" >
                          <div class="col-sm pl-0">
                            <input type="checkbox" name="pi_chkSpecialty" id="pi_chkSpecialty" (change)="checkSpecailty($event)"  formControlName="pi_chkSpecialty"/><span>&nbsp;&nbsp;&nbsp;Specialist</span><span class="text-red" *ngIf="formGroup.controls['pi_chkSpecialty'].value =='' && formGroup.controls['pi_chkPCP'].value ==''"> *</span>
                            <small *ngIf="formGroup.controls['pi_chkSpecialty'].value =='' && formGroup.controls['pi_chkPCP'].value =='' && formGroup.controls['pi_chkPCP'].touched && this.isEditable" class="help-block pl-2" >PCP or Specialist is required</small>                           
                          </div>
                        </div>
                        <div class="row" >
                          <div class="col-sm pl-0">
                            <input type="checkbox" name="pi_chksdao" id="pi_chksdao" (change)="checkSpecailty($event)"  formControlName="pi_chksdao"/><span>&nbsp;&nbsp;&nbsp;Same day access offered</span>
                          </div>
                        </div>
                      </div>
                    </div>                                        
                  </div>
                </div>                
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--SECTION 1C Provider contract information section-->
    <div style="margin-top: 15px !important;">
      <div class="row  p-t-0 m-t-0">
        <!--SECTION 1C Contract Information-->
        <div class="col-md-6">
          <div class="card" style="height: 375px;">
            <div class="card-title borderstyle " >
              <span class="headerTitle">Contract Information</span>
              <button class="btn btn-secondary-optum rounded-pill mr-3 pl-4 pr-4 pull-right btn-custom-bottom" *ngIf="!isReadOnly && !isProviderContractInfoSectionEditable" (click)="editContractInformation()">Edit</button>
              <button class="btn btn-secondary-optum rounded-pill mr-3 pull-right btn-custom-bottom " *ngIf="!isReadOnly && isProviderContractInfoSectionEditable" (click)="cancelContractInformation()">Cancel</button>
              <button class="btn btn-primary rounded-pill mr-2 pull-right pl-3 pr-3 btn-custom-bottom" *ngIf="!isReadOnly && isProviderContractInfoSectionEditable" (click)="saveProviderInformation('1c')">Save</button>
            </div>
            <div class="card-body pt-1 pb-1 pl-1 pr-1"> 
              <small *ngIf="providerContractInfoErrorMessage!=''" class="alert alert-danger">{{providerContractInfoErrorMessage}}</small>
              <!--form data entry Provider Contract Inforamtion section-->
              <form [formGroup]="formGroup">
                <div class="row mt-0 mb-0 ml-1 mr-1">
                  <div class="form-group col-md-12" >
                    <b><span class="labelHeader">HMO Information</span></b>
                    <div class="container">
                      <div class="row" >
                        <div class="col-sm pl-0" >
                          <label>HMO Commercial <span class="text-red">*</span></label>                              
                          <select class="form-control" (change)="onChangePanelStatus($event)" formControlName="ddlpc_panelStatus">
                            <option value=""></option>
                            <option *ngFor="let item of panelStatus" [value]="item.constantValue">
                              {{item.constantDesc}}
                            </option>
                          </select>                            
                          <small *ngIf="formGroup.controls['ddlpc_panelStatus'].hasError('empty') && formGroup.controls['ddlpc_panelStatus'].touched" class="help-block" >HMO Commercial status is required</small>
                        </div>
                        <div class="col-sm pl-0">
                          <label>HMO Senior <span class="text-red">*</span></label>
                          <select class="form-control" (change)="onChangeMedicareEnrollmentStatus($event)"  formControlName="ddlpc_medicareEnrollmentStatus">
                            <option></option>
                            <option *ngFor="let item of medicareStatus" [value]="item.constantValue">
                            {{item.constantDesc}}
                            </option>
                          </select>  
                          <small *ngIf="formGroup.controls['ddlpc_medicareEnrollmentStatus'].hasError('empty') && formGroup.controls['ddlpc_medicareEnrollmentStatus'].touched" class="help-block" >HMO senior status is required</small>
                        </div>
                      </div>
                    </div>                        
                  </div>
                  </div>
                  <div class="row mt-0 mb-0 ml-1 mr-1">
                    <div class="form-group col-md-12" >
                      <div class="container">
                        <div class="row" >
                          <div class="col-sm-6 pl-0" >
                            <b><span class="labelHeader">CalOptima / IEHP Information</span></b>
                            <label>Medi-Cal <span class="text-red">*</span></label>
                            <select class="form-control" (change)="onChangeMedicalEnrollmentStatus($event)"  formControlName="ddlpc_medicalEnrollmentStatus">
                              <option></option>
                              <option *ngFor="let item of medicalStatus" [value]="item.constantValue">
                              {{item.constantDesc}}
                            </option>
                            </select>
                            <small *ngIf="formGroup.controls['ddlpc_medicalEnrollmentStatus'].hasError('empty') && formGroup.controls['ddlpc_medicalEnrollmentStatus'].touched" class="help-block">Medi-Cal Status is required</small>
                          </div>    
                          <div class="col-sm-6 pl-0" >
                            <b><span class="labelHeader">&nbsp;</span></b>
                            <label>Credentialing Date </label>
                            <input maxlength="10" type="text" class="form-control"  mask="M0/d0/0000"  [clearIfNotMatch]="true" placeholder="mm/dd/yyyy"  formControlName="pc_credentialingDate"/>                            
                          </div>                
                        </div>
                      </div>
                    </div>
                  </div>                                                      
              </form>
              <!--From data entry for Provider contract information ends here-->   
            </div>
          </div>
        </div>
        <!--SECTION 5 Specialty-->
        <div class="col-md-6">
          <div class="card mt-10" style="height: 375px;">
            <div class="card-title borderstyle">
              <span class="headerTitle">Specialty</span>              
            </div>
            <div class="card-body pt-1 pb-1 pl-0 pr-1"> 
              <div class="card mb-3" *ngIf="isSpecialtySectionEditable">
                <small *ngIf="specialtyErrorMessage!=''" class=" alert alert-danger pt-0">{{specialtyErrorMessage}}</small>   
                <!--Specialty form data entry section-->
              <form [formGroup]="formGroup">   
                <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="!isReadOnly">                   
                  <div class="form-group col-sm">
                    <input type="hidden" formControlName="specialty_Id"/>    
                    <label>Provider Specialty  <span class="text-red">*</span></label>
                    <input maxlength="200" type="text" class="form-control" alphabeticOnly placeholder="Specialty" formControlName="specialty_ProviderSpecialty" (keypress)="keyPressValidation($event,'A')"/>                      
                    <small *ngIf="formGroup.controls['specialty_ProviderSpecialty'].hasError('empty') && formGroup.controls['specialty_ProviderSpecialty'].touched" class="help-block" >Provider specialty is required</small>
                  </div>
                </div>                                 
              </form>
              <!--Specialty form data entry section ends here-->
              </div> 
              <div class="card border-0" style="padding: 15px;">                     
               <div class="infotext">
                <i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;If the pre-populated specialty is incorrect, please contact your Site/Network representative for guidance.</div>  
               <div id="specialtyDiv"  >
                <div class="row underline" *ngFor="let row of providerSpecialty$">
                  <div class="col">
                    {{row.specialty}}
                  </div>
               </div>
               </div>
                   
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--SECTION 1B - Provider License -->
    <div style="margin-top: 15px !important;">
      <div class="row" *ngIf="isLoading">
          <div class="col-md-12">
            <div class="card">
              <div class="card-title borderstyle " >
                <span class="headerTitle">License(s)</span>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pl-4 pr-4 pull-right btn-custom-bottom" *ngIf="!isReadOnly && !isProviderLicenseSectionEditable" (click)="editProviderLicense()">Edit</button>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pull-right btn-custom-bottom " *ngIf="!isReadOnly && isProviderLicenseSectionEditable" (click)="cancelProviderLicense()">Cancel</button>
                <button class="btn btn-primary rounded-pill mr-2 pull-right pl-3 pr-3 btn-custom-bottom" *ngIf="!isReadOnly && isProviderLicenseSectionEditable" (click)="saveProviderInformation('1b')">Save</button>
              </div>
              <div class="card-body pt-1 pb-1 pl-1 pr-1"> 
                <small *ngIf="providerLicenseErrorMessage!=''" class="alert alert-danger">{{providerLicenseErrorMessage}}</small>
                <!--form data entry section-->
                <form [formGroup]="formGroup">                  
                  <div class="row mt-0 mb-0 ml-1 mr-1">
                    <div class="form-group col-md-3">
                      <label>Medi-Cal # </label>
                      <input maxlength="20" type="text" class="form-control"  placeholder="Medi-cal #"  formControlName="pl_medicalnumber" (keypress)="keyPressValidation($event,'G')"/>  
                    </div>                       
                    <div class="form-group col-md-3">
                      <label>Medicare #</label>
                      <input maxlength="20" type="text" class="form-control"  placeholder="Medicare #"  formControlName="pl_medicarenumber" (keypress)="keyPressValidation($event,'G')"/>  
                    </div>
                    <!-- <div class="form-group col-md-2">
                      <label>UPIN #</label>
                      <input maxlength="20" type="text" class="form-control" mask="0*" placeholder="UPIN #"  formControlName="pl_upin" />  
                    </div> -->
                    <div class="form-group col-md-2">
                      <label>DEA #</label>
                      <input maxlength="20" type="text" class="form-control"  placeholder="DEA #"  formControlName="pl_dea" readonly (keypress)="keyPressValidation($event,'C')"/>                        
                    </div>                                           
                    <div class="form-group col-md-2">
                      <label>State License # <span class="text-red">*</span></label>
                      <input maxlength="20" type="text" class="form-control"  placeholder="license #"  formControlName="pl_statelicense" (keypress)="keyPressValidation($event,'C')" readonly/>                        
                      <small *ngIf="formGroup.controls['pl_statelicense'].hasError('empty') && formGroup.controls['pl_statelicense'].touched" class="help-block" >State License is required</small>
                    </div>
                    <div class="form-group col-md-2">
                      <label>State License Exp Date <span class="text-red">*</span> </label>                          
                      <input maxlength="10" type="text" class="form-control" mask="M0/d0/0000"  [clearIfNotMatch]="true"  placeholder="mm/dd/yyyy"  formControlName="pl_statelicenseexpdate"/>  
                      <small *ngIf="formGroup.controls['pl_statelicenseexpdate'].hasError('empty') && formGroup.controls['pl_statelicenseexpdate'].touched" class="help-block" >State License Exp Date is required</small>
                    </div>
                  </div>                                     
                </form>
                <!--From data entry for Provider licenses ends here-->                
              </div>
            </div>
          </div>
      </div>
    </div>  
    <!--SECTION 2 - Board Training(s) -->    
    <div style="margin-top: 15px !important;">
      <div class="row ">
          <div class="col-md-12">
            <div class="card">
              <div class="card-title borderstyle " >
                <span class="headerTitle">Board Training(s)</span>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pl-4 pr-4 pull-right btn-custom-bottom" *ngIf="!isReadOnly && !isProviderBoardTrainingSectionEditable" (click)="editProviderBoardTraining()">Edit</button>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pull-right btn-custom-bottom " *ngIf="!isReadOnly && isProviderBoardTrainingSectionEditable" (click)="cancelProviderBoardTraining()">Cancel</button>
                <button class="btn btn-primary rounded-pill mr-2 pull-right pl-3 pr-3 btn-custom-bottom" *ngIf="!isReadOnly && isProviderBoardTrainingSectionEditable" (click)="saveProviderBoardTraining()">Save</button>
              </div>
              <div class="card-body pt-1 pb-1 pl-1 pr-1"> 
                <small *ngIf="providerLicenseErrorMessage!=''" class="alert alert-danger">{{providerLicenseErrorMessage}}</small>
                <!--form data entry section-->
                <form [formGroup]="boardSectionGroup">
                  <div class="row mt-0 mb-0 ml-1 mr-1">                    
                    <div class="form-group col-md-3">
                      <label>Board Name</label>
                      <input maxlength="250" type="text" class="form-control"  placeholder="Board Name" formControlName="pb_boardname"/>                      
                    </div>
                    <div class="form-group col-md-3" >                     
                      <label>Board Certification Status <span *ngIf="boardSectionGroup.get('ddlpb_boardCertificationStatus')?.hasError('required')" class="text-red">*</span></label>
                        <select class="form-control" formControlName="ddlpb_boardCertificationStatus">
                          <option></option>
                          <option *ngFor="let item of boardStatus" [value]="item.constantValue">
                          {{item.constantDesc}}
                        </option>                            
                        </select>   
                        <div class="help-block" *ngIf="boardSectionGroup.controls['ddlpb_boardCertificationStatus']?.hasError('required') && boardSectionGroup.controls['ddlpb_boardCertificationStatus']?.touched ">Board certification is required</div> 
                    </div>
                    <div class="form-group col-md-3" >
                      <label>Effective Date<span class="text-red" *ngIf="boardSectionGroup.get('pb_effectivedate')?.hasError('required')">*</span></label>                        
                      <input maxlength="10" type="text" class="form-control" mask="M0/d0/0000"  [clearIfNotMatch]="true"  placeholder="mm/dd/yyyy"  formControlName="pb_effectivedate"/>  
                      <div class="help-block" *ngIf="boardSectionGroup.controls['pb_effectivedate']?.hasError('required') && boardSectionGroup.controls['pb_effectivedate']?.touched ">Effective Date is required</div>                       
                      <div class="help-block" *ngIf="!boardExpirationDateIsValid() && isProviderBoardTrainingSectionEditable">Invalid effective or expiration date Range</div>                      
                    </div>
                    <div class="form-group col-md-3" >
                      <label>Expiration Date<span class="text-red" *ngIf="boardSectionGroup.get('pb_expirationdate')?.hasError('required')">*</span></label>                        
                      <input maxlength="10" type="text" class="form-control" mask="M0/d0/0000"  [clearIfNotMatch]="true"  placeholder="mm/dd/yyyy"  formControlName="pb_expirationdate"/>   
                      <div class="help-block" *ngIf="boardSectionGroup.controls['pb_expirationdate']?.hasError('required') && boardSectionGroup.controls['pb_expirationdate']?.touched ">Expiration Date is required</div>                       
                    </div>
                  </div>                
                </form>
                <!--From data entry for board training ends here--> 
              </div>
            </div>
          </div>
      </div>
    </div>
    <!--SECTION 3 - Provider Liabilty/Malpractice Insurance section-->
    <div style="margin-top: 15px !important;">
      <div class="row ">
          <div class="col-md-12">
            <div class="card">
              <div class="card-title borderstyle " >
                <span class="headerTitle">Liability / Malpractice Insurance(s)</span>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pl-4 pr-4 pull-right btn-custom-bottom" *ngIf="!isReadOnly && !isProviderLiabilitySectionEditable" (click)="editProviderLiability()">Edit</button>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pull-right btn-custom-bottom btn-custom-bottom" *ngIf="!isReadOnly && isProviderLiabilitySectionEditable" (click)="cancelProviderLiability()">Cancel</button>
                <button class="btn btn-primary rounded-pill mr-2 pull-right pl-3 pr-3 btn-custom-bottom" *ngIf="!isReadOnly && isProviderLiabilitySectionEditable" (click)="saveProviderInsurance()">Save</button>
              </div>
              <div class="card-body pt-1 pb-1 pl-1 pr-1"> 
                  <small *ngIf="providerLiabilityErrorMessage!=''" class="alert alert-danger">{{providerLiabilityErrorMessage}}</small>
                  <!--form data entry Provider Liabilty/Malpractice Insurance section-->
                  <form [formGroup]="formGroup" >
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="form-group col-md-3" >
                        <label>Carrier<span class="text-red">*</span></label>
                        <input maxlength="50" type="text" class="form-control"  placeholder="Carrier Name"  formControlName="pm_carriername" (keypress)="keyPressValidation($event,'A')"/>  
                        <small *ngIf="formGroup.controls['pm_carriername'].hasError('empty') && formGroup.controls['pm_carriername'].touched" class="help-block" >Carrier Name is required</small>                           
                      </div>
                      <div class="form-group col-md-3" >
                        <label>Policy No<span class="text-red">*</span></label>
                        <input maxlength="20" type="text" class="form-control"  placeholder="Policy Number"  formControlName="pm_policynumber" (keypress)="keyPressValidation($event,'E')"/>  
                        <small *ngIf="formGroup.controls['pm_policynumber'].hasError('empty') && formGroup.controls['pm_policynumber'].touched" class="help-block" >Policy Number is required</small>                           
                      </div>
                      <div class="form-group col-md-2" >
                        <label>Expiration Date<span class="text-red">*</span></label>
                        <input maxlength="10" type="text" class="form-control" mask="M0/d0/0000"  [clearIfNotMatch]="true" placeholder="mm/dd/yyyy"  formControlName="pm_expirationdate"/>  
                        <small *ngIf="formGroup.controls['pm_expirationdate'].hasError('empty') && formGroup.controls['pm_expirationdate'].touched" class="help-block" >Expiration Date is required</small>                           
                      </div>
                      <div class="form-group col-md-4 col-xs-12 " >
                        <label>Amount($)<span class="text-red">*</span></label>
                          <div class="container">
                            <div class="row" >
                              <div class="col-sm pl-0">
                                <input maxlength="9" type="text" class="form-control"  mask="separator.2" thousandSeparator="," placeholder="Min" formControlName="pm_amountMin" (input)="validateLiabilityAmount($event.target)" />
                                <small *ngIf="formGroup.controls['pm_amountMin'].hasError('empty') && formGroup.controls['pm_amountMin'].touched" class="help-block">Min Amount is required</small>                                  
                                <small *ngIf="!isValidAmountRange && !formGroup.controls['pm_amountMin'].hasError('empty')" class="help-block">Invalid Amount Range.</small>
                              </div>
                              <span>-</span>
                              <div class="col-sm">
                                <input maxlength="9" type="text" class="form-control"  mask="separator.2" thousandSeparator="," placeholder="Max" formControlName="pm_amountMax" (input)="validateLiabilityAmount($event.target)"/>
                                <small *ngIf="formGroup.controls['pm_amountMax'].hasError('empty') && formGroup.controls['pm_amountMax'].touched" class="help-block">Max amount is required</small>                                                   
                              </div>                                 
                            </div>                            
                            
                          </div>
                      </div>                       
                    </div>                    
                  </form>
                  <!--From data entry for Provider licenses and board training ends here-->   
              </div>
            </div>
          </div>
      </div>
    </div>
    <!--SECTION 4 - Office Information-->
    <div style="margin-top: 15px !important;">
      <div class="row ">
          <div class="col-md-12">
            <div class="card">
              <div class="card-title borderstyle " >
                <span class="headerTitle">Office Information</span>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pl-4 pr-4 pull-right btn-custom-bottom" *ngIf="!isReadOnly && !isOfficeInformationSectionEditable && !isMaxLocations()" (click)="addOfficeInformation()">Add</button>
                <button class="btn btn-secondary-optum rounded-pill mr-3 pull-right btn-custom-bottom btn-custom-bottom" *ngIf="!isReadOnly && isOfficeInformationSectionEditable" (click)="cancelOfficeInformation()">Cancel</button>
                <button class="btn btn-primary rounded-pill mr-2 pull-right pl-3 pr-3 btn-custom-bottom" *ngIf="!isReadOnly && isOfficeInformationSectionEditable" (click)="saveOfficeInformation()">Save</button>
              </div>
              <div class="card-body pt-1 pb-1 pl-1 pr-1">
                <div class="infotext ml-3 mb-3">
                  <i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;A maximum of two office locations and one billing location can be entered. If you need to report recent changes to additional locations please reach out to your Site/Network representative
                </div>   
                <div class="card mb-3" *ngIf="isOfficeInformationSectionEditable">
                  <small *ngIf="officeInformationErrorMessage!=''" class="alert alert-danger ml-3 mr-3">{{officeInformationErrorMessage}}</small>
                  <!--form data entry section-->
                  <form [formGroup]="formGroup">
                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="!isReadOnly">
                      <div class="form-group col-md-2">
                        <input type="hidden" formControlName="office_Id"/>
                        <label>Type <span class="text-red">*</span></label>
                        <select class="form-control" (change)="onChangeOfficeType($event)" formControlName="ddloffice_Type">
                          <option value="Primary">Primary</option>
                          <option value="Secondary">Secondary</option>
                          <option value="Billing">Billing</option>
                        </select>
                        <small *ngIf="formGroup.controls['ddloffice_Type']?.value=='' && formGroup.controls['ddloffice_Type']?.touched" class="help-block" >Office type is required</small>
                      </div>
                      <div class="form-group col-md-3">
                        <label>Address<span class="text-red">*</span> <small>(Line 1)</small></label>                        
                        <input maxlength="200" type="text" class="form-control" alphabeticOnly placeholder="Address" formControlName="office_Address1" (keypress)="keyPressValidation($event,'F')"/>
                        <small *ngIf="formGroup.controls['office_Address1'].hasError('empty') && formGroup.controls['office_Address1'].touched" class="help-block" >Address is required</small>
                      </div>  
                      <div class="form-group col-md-3">
                        <label>Address <small>(Line 2)</small></label>                        
                        <input maxlength="200" type="text" class="form-control" alphabeticOnly placeholder="Address" formControlName="office_Address2" (keypress)="keyPressValidation($event,'F')"/>
                        <small *ngIf="formGroup.controls['office_Address2'].hasError('invalidChar') && formGroup.controls['office_Address2'].touched" class="help-block">Invalid special character</small>                        
                      </div>
                      <div class="form-group col-md-2" >
                        <label>City<span class="text-red">*</span> </label>
                        <input maxlength="50" type="text" class="form-control" alphabeticOnly placeholder="City" formControlName="office_City" (keypress)="keyPressValidation($event,'A')"/>
                        <small *ngIf="formGroup.controls['office_City'].hasError('empty') && formGroup.controls['office_City'].touched" class="help-block" >City is required</small>
                      </div>
                      <div class="form-group col-md-2 " >
                        <label>State<span class="text-red">*</span> </label>
                        <input maxlength="50" type="text" class="form-control" alphabeticOnly placeholder="State" formControlName="office_State" (keypress)="keyPressValidation($event,'A')"/>
                        <small *ngIf="formGroup.controls['office_State'].hasError('empty') && formGroup.controls['office_State'].touched" class="help-block" >State is required</small>
                      </div>                      
                    </div>

                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="!isReadOnly">
                      <div class="form-group col-md-2 " >
                        <label>Zip<span class="text-red">*</span> </label>
                        <input maxlength="10" type="text" class="form-control" alphabeticOnly placeholder="Zip" formControlName="office_Zip" (keypress)="keyPressValidation($event,'D')"/>
                        <small *ngIf="formGroup.controls['office_Zip'].hasError('empty') && formGroup.controls['office_Zip'].touched" class="help-block" >Zip is required</small>
                      </div>
                      <div class="form-group col-md-3">
                        <label>Phone <span class="text-red">*</span></label>
                        <input maxlength="14" type="text" class="form-control" alphabeticOnly  mask="(000) 000-0000"   formControlName="office_Phone"/>
                        <small *ngIf="formGroup.controls['office_Phone'].hasError('empty') && formGroup.controls['office_Phone'].touched" class="help-block" >Phone is required</small>
                        <small *ngIf="formGroup.controls['office_Phone'].hasError('pattern') && formGroup.controls['office_Phone'].touched" class="help-block" >Invalid Phone</small>
                      </div>                     
                      <div class="form-group col-md-3" >
                        <label>Fax <span class="text-red">*</span></label>
                        <input maxlength="14" type="text" class="form-control" alphabeticOnly mask="(000) 000-0000" formControlName="office_Fax"/>
                        <small *ngIf="formGroup.controls['office_Fax'].hasError('empty') && formGroup.controls['office_Fax'].touched" class="help-block" >Fax is required</small>
                        <small *ngIf="formGroup.controls['office_Fax'].hasError('pattern') && formGroup.controls['office_Fax'].touched" class="help-block" >Invalid Fax</small>
                      </div> 
                      <div class="form-group col-md-4 " *ngIf="SelectedOfficeType!='Billing'">
                        <label>Office hours <small>(Mon to Sun)</small> </label>
                        <div class="container">
                          <div class="row" >
                            <div class="col-sm-4 ml-0 mr-0 pr-0 pl-0">
                              <input type="time" id="toOfficeHours" name="toOfficeHours" class="form-control" (change)="onChangetoOfficeHours($event)"  formControlName="office_ToHour" readonly/>
                            </div>
                            <div class="col-sm-1 ml-0 mr-0 pr-0 pl-0 text-center">
                              <span>to</span>
                            </div>                          
                            <div class="col-sm-4 ml-0 mr-0 pr-0 pl-0">
                              <input type="time" id="fromOfficeHours" name="fromOfficeHours" class="form-control" (change)="onChangefromOfficeHours($event)" formControlName="office_FromHour" readonly/>                            
                            </div>
                            <div class="col-sm-2 ml-0 mr-0 pr-0 pl-0">
                              <div class="btnAddcircle textCenter" title="Click to view details"><a *ngIf="!isReadOnly" (click)="OpenOfficeHoursModal()"><i class="fa fa-plus" aria-hidden="true" *ngIf="isOfficeInformationAddClick"></i><i class="fa fa-pencil" aria-hidden="true" *ngIf="!isOfficeInformationAddClick"></i></a></div>
                            </div>

                          </div>
                          <div class="row">
                            <small *ngIf="(formGroup.controls['office_FromHour'].value < formGroup.controls['office_ToHour'].value) && (!formGroup.controls['office_FromHour'].hasError('empty')) && (!formGroup.controls['office_ToHour'].hasError('empty'))" class="help-block">Invalid office hour Range.</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                    <!--form data entry section end here-->
                </div>                
                <div class="table-responsive">
                  <table class="table table-striped" style="margin-left:10px; ">
                      <thead class="m-b-0 m-t-0">
                        <tr class="d-flex">                              
                          <th class="col-sm-1" style="border:0px;">Type</th>
                          <th class="col-sm-2" style="border:0px;">Phone</th>
                          <th class="col-sm-2" style="border:0px;">Fax</th>                              
                          <th class="col-sm-3" style="border:0px;">Office Hours <small>(Mon to Sun)</small></th>
                          <th class="col-sm-3" style="border:0px;">Address</th>                            
                          <th class="col-sm-1" *ngIf="!isReadOnly">Action</th>
                          <th style="visibility: hidden;"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  class="d-flex" *ngFor="let row of providerOfficeInformation$">                                  
                              <td class="col-sm-1 ml-1">{{row.locationType}}</td>
                              <td class="col-sm-2">{{row.phone | formatPhone}}</td>
                              <td class="col-sm-2">{{row.fax | formatPhone}}</td>                                  
                              <td class="col-sm-3" *ngIf="row.locationType!='Billing'">Mon {{convertTime(row.monHours)}} <div class="btncircle textCenter" title="Click to view details" *ngIf="!isOfficeInformationSectionEditable"><a  (click)="viewOfficeHoursModal(row.locationId)"><i class="fa fa-eye" aria-hidden="true"></i></a></div></td>
                              <td class="col-sm-3" *ngIf="row.locationType=='Billing'"></td>
                              <td class="col-sm-3">{{row.streetAddress1}} {{row.streetAddress2}} {{row.city}} {{row.state}} {{row.zip}}  </td>
                              <td class="col-sm-1" *ngIf="!isReadOnly">
                                <a class="btn-text-color " style="padding: 2px;" (click)="editOfficeInformation(row.locationId)"> Edit </a>
                                <span> | </span>
                                <a class="btn-text-color " style="padding: 2px;" (click)="deleteOfficeInformation(row.locationId)"> Delete</a>
                              </td>
                              <td style="visibility: hidden;">{{row.locationId}}</td>
                            </tr>
                          </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
<!-- End Add Patient -->
        <!--Attestation section-->
        <div style="margin-top: 10px !important;">
          <div class="row pb-2">
              <div class="col-md-12 textCenter" *ngIf="!isReadOnly">
                <input class="attest-checkbox" type="checkbox" name="chkAttestation" id="chkAttestation" (change)="attestationCheck($event)"/>
                <span class="attest-text"><b> I have reviewed and attest to the accuracy of my provider profile.</b></span>
              </div>
          </div>
          <div class="row ">
            <div class="col-md-12 textCenter">
              <button #btnAttest type="button" disabled="true" (click)="SaveAttestation()" class="btn btn-tab btn-primary" *ngIf="!isReadOnly">Attest</button>
            </div>
          </div>
        </div>
      </div>
    <router-outlet></router-outlet>

<!-- Add Office Hours Modal-->
<lte-modal
  #addOfficeHoursModal
  [submitButtonLabel]="showReadonlyModal ? '' : 'Submit'"
  [disableSubmitButton]="addOfficeHoursForm ? !addOfficeHoursForm.valid: true"
  [isFormModal]="true"
  [closeOnOutsideClick]="showReadonlyModal"
  (onSubmit)="addOfficeHours()" 
  (onClose)="resetOfficeHours()"
  (onOpen)="intializeOfficeHours()" 
  [cancelButtonLabel]="showReadonlyModal ? '' : 'Cancel'"
  [contentWidth]="modalwidth"
>
  <modal-header><b>Office Hours</b></modal-header>
  <modal-body>
    <app-add-office-hour 
      #addOfficeHourComponent
      [onInit]="initAddOfficeHoursForm.asObservable()"
      (validate)="onAddOfficeHoursValidate($event)"     
      [isReadonly]="showReadonlyModal"
    ></app-add-office-hour>
  </modal-body>
</lte-modal>

<!-- Delete record pop up-->
<lte-modal #alertConfirmationModal submitButtonLabel="Delete" cancelButtonLabel="Cancel" contentWidth="420px">
  <modal-header>
    <b><i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color: #ffc107;"></i> Confirm</b></modal-header>
  <modal-body> Do you want to delete this record? </modal-body>
</lte-modal>

<!-- Delete record pop up-->
<lte-modal #alertAttestationConfirmationModal submitButtonLabel="Submit" cancelButtonLabel="Cancel" contentWidth="420px">
  <modal-header>
    <b><i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color: #ffc107;"></i> Confirm</b></modal-header>
  <modal-body> After submission you can not make any changes. Are you sure you want to submit the attestation form?  </modal-body>
</lte-modal>