<form [formGroup]="formGroup"> 
  <div *ngIf="!isReadonly">
    <div class="row ml-3">
      <div class="form-group col-md-1">
        <label>Mon </label>
      </div>
      <div class="form-group col-md-8">
        <div class="container">
          <div class="row" *ngIf="!isMonClose">
            <div class="col-md-5 pr-0 ">
              <input type="time" id="office_MonFromHour" name="office_MonFromHour" class="form-control" (input)="validateDayRange($event.target,'Mon')"  formControlName="office_MonToHour"/>
              <small *ngIf="formGroup.controls['office_MonToHour'].hasError('empty') && formGroup.controls['office_MonToHour'].touched" class="help-block" >From hour is required</small>              
            </div>           
            <div class="col-md-2 pr-0 text-center">
              <span>-</span>
            </div>
            <div class="col-md-5 pr-0">
              <input type="time" id="office_MonToHour" name="office_MonToHour" class="form-control" (input)="validateDayRange($event.target,'Mon')" formControlName="office_MonFromHour"/>        
              <small *ngIf="formGroup.controls['office_MonFromHour'].hasError('empty') && formGroup.controls['office_MonFromHour'].touched" class="help-block" >To hour is required</small>                    
            </div>
            <small *ngIf="!isValidMonRange" class="help-block pl-3">Invalid Mon office hours range.</small>
          </div> 
          <div class="row " *ngIf="isMonClose">
            <div class="col-md-12 pr-0 ">
              <label>Closed</label>
            </div>            
          </div>        
        </div>
      </div>
      <div class="form-group col-md-3 pt-1  pr-0">
        <input #chkoffice_Mon type="checkbox" name="chkoffice_Mon" id="chkoffice_Mon" (change)="closedOfficeHoursCheck($event,'Mon')" formControlName="chkoffice_Mon"/>
        <span> Closed</span>
      </div>  
    </div>    
    <div class="row content-copy-text" *ngIf="isValidMonRange && isMonToValue && !isMonClose && !isSameAsMonday && !(formGroup.controls['office_MonFromHour'].hasError('empty') && formGroup.controls['office_MonToHour'].touched)
    && !(formGroup.controls['office_MonToHour'].hasError('empty') && formGroup.controls['office_MonToHour'].touched)">
      <div class="form-group infotext col-md-9">
        <input class="copyoffice-checkbox" type="checkbox" name="chkSameAsMon" id="chkSameAsMon" (change)="applyMondayHoursToAll($event)" />
        <span class="copy-text"> Apply these hours to all days through Friday</span>
      </div>
    </div>
    <div class="row ml-3">
      <div class="form-group col-md-1">
        <label>Tue </label>
      </div>
      <div class="form-group col-md-8">
        <div class="container">
          <div class="row" *ngIf="!isTueClose">
            <div class="col-md-5 pr-0 ">
              <input type="time" id="office_TueFromHour" name="office_TueFromHour" class="form-control"  (input)="validateDayRange($event.target,'Tue')"  formControlName="office_TueToHour"/>
              <small *ngIf="formGroup.controls['office_TueToHour'].hasError('empty') && formGroup.controls['office_TueToHour'].touched" class="help-block" >From hour is required</small>              
            </div>
            <div class="col-md-2 pr-0 text-center">
              <span>-</span>
            </div>
            <div class="col-md-5 pr-0">
              <input type="time" id="office_TueToHour" name="office_TueToHour" class="form-control"  (input)="validateDayRange($event.target,'Tue')" formControlName="office_TueFromHour"/>                      
              <small *ngIf="formGroup.controls['office_TueFromHour'].hasError('empty') && formGroup.controls['office_TueFromHour'].touched" class="help-block" >To hour is required</small>                    
            </div>
            <small *ngIf="!isValidTueRange" class="help-block pl-3">Invalid Tue office hours range.</small>
          </div>
          <div class="row " *ngIf="isTueClose">
            <div class="col-md-12 pr-0 ">
              <label>Closed</label>
            </div>            
          </div> 
        </div>
      </div>
      <div class="form-group col-md-3 pt-1  pr-0">
        <input #chkoffice_Tue type="checkbox" name="chkoffice_Tue" id="chkoffice_Tue"  (change)="closedOfficeHoursCheck($event,'Tue')" formControlName="chkoffice_Tue"/>
        <span> Closed</span>
      </div>  
    </div>     
    <div class="row ml-3">
      <div class="form-group col-md-1">
        <label>Wed </label>
      </div>
      <div class="form-group col-md-8">
        <div class="container">
          <div class="row" *ngIf="!isWedClose">
            <div class="col-md-5 pr-0 ">
              <input type="time" id="office_WedFromHour" name="office_WedFromHour" class="form-control"  (input)="validateDayRange($event.target,'Wed')"  formControlName="office_WedToHour"/>
              <small *ngIf="formGroup.controls['office_WedToHour'].hasError('empty') && formGroup.controls['office_WedToHour'].touched" class="help-block" >From hour is required</small>                    
            </div>
            <div class="col-md-2 pr-0 text-center">
              <span>-</span>
            </div>
            <div class="col-md-5 pr-0">
              <input type="time" id="office_WedToHour" name="office_WedToHour" class="form-control"  (input)="validateDayRange($event.target,'Wed')" formControlName="office_WedFromHour"/>                            
              <small *ngIf="formGroup.controls['office_WedFromHour'].hasError('empty') && formGroup.controls['office_WedFromHour'].touched" class="help-block" >To hour is required</small>                    
            </div>
            <small *ngIf="!isValidWedRange" class="help-block pl-3">Invalid Wed office hours range.</small>
          </div>
          <div class="row " *ngIf="isWedClose">
            <div class="col-md-12 pr-0 ">
              <label>Closed</label>
            </div>            
          </div> 
        </div>
      </div>
      <div class="form-group col-md-3 pt-1  pr-0">
        <input #chkoffice_Wed type="checkbox" name="chkoffice_Wed" id="chkoffice_Wed"  (change)="closedOfficeHoursCheck($event,'Wed')" formControlName="chkoffice_Wed"/>
        <span> Closed</span>
      </div>  
    </div>    
    <div class="row ml-3">
      <div class="form-group col-md-1">
        <label>Thu </label>
      </div>
      <div class="form-group col-md-8">
        <div class="container">
          <div class="row" *ngIf="!isThuClose">
            <div class="col-md-5 pr-0 ">
              <input type="time" id="office_ThuFromHour" name="office_ThuFromHour" class="form-control"  (input)="validateDayRange($event.target,'Thu')"  formControlName="office_ThuToHour"/>
              <small *ngIf="formGroup.controls['office_ThuToHour'].hasError('empty') && formGroup.controls['office_ThuToHour'].touched" class="help-block" >From hour is required</small>                    
            </div>
            <div class="col-md-2 pr-0 text-center">
              <span>-</span>
            </div>
            <div class="col-md-5 pr-0">
              <input type="time" id="office_ThuToHour" name="office_ThuToHour"  class="form-control"  (input)="validateDayRange($event.target,'Thu')" formControlName="office_ThuFromHour"/>                            
              <small *ngIf="formGroup.controls['office_ThuFromHour'].hasError('empty') && formGroup.controls['office_ThuFromHour'].touched" class="help-block" >To hour is required</small>                    
            </div>
            <small *ngIf="!isValidThuRange" class="help-block pl-3">Invalid Thu office hours range.</small>
          </div>
          <div class="row " *ngIf="isThuClose">
            <div class="col-md-12 pr-0 ">
              <label>Closed</label>
            </div>            
          </div> 
        </div>
      </div>
      <div class="form-group col-md-3 pt-1  pr-0">
        <input #chkoffice_Thu type="checkbox" name="chkoffice_Thu" id="chkoffice_Thu"   (change)="closedOfficeHoursCheck($event,'Thu')" formControlName="chkoffice_Thu"/>
        <span> Closed</span>
      </div>  
    </div>    
    <div class="row ml-3">
      <div class="form-group col-md-1">
        <label>Fri </label>
      </div>
      <div class="form-group col-md-8">
        <div class="container">
          <div class="row" *ngIf="!isFriClose">
            <div class="col-md-5 pr-0 ">
              <input type="time" id="office_FriFromHour" name="office_FriFromHour" class="form-control"  (input)="validateDayRange($event.target,'Fri')"  formControlName="office_FriToHour"/>
              <small *ngIf="formGroup.controls['office_FriToHour'].hasError('empty') && formGroup.controls['office_FriToHour'].touched" class="help-block" >From hour is required</small>                    
            </div>
            <div class="col-md-2 pr-0 text-center">
              <span>-</span>
            </div>
            <div class="col-md-5 pr-0">
              <input type="time" id="office_FriToHour" name="office_FriToHour" class="form-control"  (input)="validateDayRange($event.target,'Fri')" formControlName="office_FriFromHour"/>                            
              <small *ngIf="formGroup.controls['office_FriFromHour'].hasError('empty') && formGroup.controls['office_FriFromHour'].touched" class="help-block" >To hour is required</small>                    
            </div>
            <small *ngIf="!isValidFriRange" class="help-block pl-3">Invalid Fri office hours range.</small>
          </div>
          <div class="row " *ngIf="isFriClose">
            <div class="col-md-12 pr-0 ">
              <label>Closed</label>
            </div>            
          </div> 
        </div>
      </div>
      <div class="form-group col-md-3 pt-1  pr-0">
        <input #chkoffice_Fri type="checkbox" name="chkoffice_Fri" id="chkoffice_Fri"   (change)="closedOfficeHoursCheck($event,'Fri')" formControlName="chkoffice_Fri"/>
        <span> Closed</span>
      </div>  
    </div>    
    <div class="row ml-3">
      <div class="form-group col-md-1">
        <label>Sat </label>
      </div>
      <div class="form-group col-md-8">
        <div class="container">
          <div class="row" *ngIf="!isSatClose">
            <div class="col-md-5 pr-0 ">
              <input type="time" id="office_SatFromHour" name="office_SatFromHour" class="form-control"  (input)="validateDayRange($event.target,'Sat')"  formControlName="office_SatToHour"/>
              <small *ngIf="formGroup.controls['office_SatToHour'].hasError('empty') && formGroup.controls['office_SatToHour'].touched" class="help-block" >From hour is required</small>                    
            </div>
            <div class="col-md-2 pr-0 text-center">
              <span>-</span>
            </div>
            <div class="col-md-5 pr-0">
              <input type="time" id="office_SatToHour" name="office_SatToHour" class="form-control"  (input)="validateDayRange($event.target,'Sat')" formControlName="office_SatFromHour"/>                            
              <small *ngIf="formGroup.controls['office_SatFromHour'].hasError('empty') && formGroup.controls['office_SatFromHour'].touched" class="help-block" >To hour is required</small>                    
            </div>
            <small *ngIf="!isValidSatRange" class="help-block pl-3">Invalid Sat office hours range.</small>
          </div>
          <div class="row " *ngIf="isSatClose">
            <div class="col-md-12 pr-0 ">
              <label>Closed</label>
            </div>            
          </div> 
        </div>
      </div>
      <div class="form-group col-md-3 pt-1  pr-0">
        <input #chkoffice_Sat type="checkbox" name="chkoffice_Sat" id="chkoffice_Sat"   (change)="closedOfficeHoursCheck($event,'Sat')" formControlName="chkoffice_Sat"/>
        <span> Closed</span>
      </div>  
    </div>    
    <div class="row ml-3">
      <div class="form-group col-md-1">
        <label>Sun </label>
      </div>
      <div class="form-group col-md-8">
        <div class="container">
          <div class="row" *ngIf="!isSunClose">
            <div class="col-md-5 pr-0 ">
              <input type="time" id="office_SunFromHour" name="office_SunFromHour" class="form-control"  (input)="validateDayRange($event.target,'Sun')"  formControlName="office_SunToHour"/>
              <small *ngIf="formGroup.controls['office_SunToHour'].hasError('empty') && formGroup.controls['office_SunToHour'].touched" class="help-block" >From hour is required</small>                    
            </div>
            <div class="col-md-2 pr-0 text-center">
              <span>-</span>
            </div>
            <div class="col-md-5 pr-0">
              <input type="time" id="office_SunToHour" name="office_SunToHour" class="form-control"  (input)="validateDayRange($event.target,'Sun')" formControlName="office_SunFromHour"/>                            
              <small *ngIf="formGroup.controls['office_SunFromHour'].hasError('empty') && formGroup.controls['office_SunFromHour'].touched" class="help-block" >To hour is required</small>                    
            </div>
            <small *ngIf="!isValidSunRange" class="help-block pl-3">Invalid Sun office hours range.</small>
          </div>
          <div class="row " *ngIf="isSunClose">
            <div class="col-md-12 pr-0 ">
              <label>Closed</label>
            </div>            
          </div> 
        </div>
      </div>
      <div class="form-group col-md-3 pt-1  pr-0">        
        <input #chkoffice_Sun type="checkbox" name="chkoffice_Sun" id="chkoffice_Sun"   (change)="closedOfficeHoursCheck($event,'Sun')" formControlName="chkoffice_Sun"/>
        <span> Closed</span>
      </div>  
    </div>
  </div>  
    <div *ngIf="isReadonly">
      <table  style="width: 100%;">
        <tr class="tableRow"><td>Mon</td> <td class="textCenter"><span>{{monOfficeHrs}}</span></td></tr>
        <tr class="tableRow"><td>Tue</td> <td class="textCenter"><span>{{tueOfficeHrs}}</span></td></tr>
        <tr class="tableRow"><td>Wed</td> <td class="textCenter"><span>{{wedOfficeHrs}}</span></td></tr>
        <tr class="tableRow"><td>Thu</td> <td class="textCenter"><span>{{thuOfficeHrs}}</span></td></tr>
        <tr class="tableRow"><td>Fri</td> <td class="textCenter"><span>{{friOfficeHrs}}</span></td></tr>
        <tr class="tableRow"><td>Sat</td> <td class="textCenter"><span>{{satOfficeHrs}}</span></td></tr>
        <tr ><td>Sun</td> <td class="textCenter"><span>{{sunOfficeHrs}}</span></td></tr>
      </table>     
    </div>
</form>
