<div class="container">
  <div class="login-section">
    <div class="login-box box box-default box-logout" >
        <div boxColor="primary" >
          <div class="login-logo-section justify-content-center pt-20 align-items-center">
            <span class="optum-logo">
              <img src="../../../../assets/img/optum.svg" class="" alt="Optum" width="100" />
            </span>
            <span class="PA-txt pl-3">Provider Attestation</span>
          </div>
          <div class="login-box-body pb-0">
            <div class="row login-box-content">
              <div class="text-center w-100"><i _ngcontent-ng-c72015181="" aria-hidden="true" class="fa fa-sign-in icon-box"></i></div>
              <p>You have been successfully logged out. To log back in please select the Sign In button below.</p>
            </div>
          </div>
          <div class="row pt-10 login-box-content-center">
            <div class="col-xs-12 form-group text-center">
              <button type="button" (click)="signIn();" class="btn btn-primary mb-3">Sign In</button>
            </div>
          </div>
          <div class="footer d-flex login-box-content-center">
            <span>{{copyright}}</span>
          </div>
        </div>
    </div>
</div>
</div>
