import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule, AuthInterceptor, StsConfigHttpLoader, StsConfigLoader, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { map, Observable } from 'rxjs';

export const getOpenIdConfiguration = (runtimeConfigLoaderService: RuntimeConfigLoaderService): Observable<OpenIdConfiguration> => {
    return runtimeConfigLoaderService.loadConfig().pipe(map(() => {
        //debugger;
        let env = runtimeConfigLoaderService.getConfigObjectKey('ENV');
        console.log('config env:' + env);
        return {
            authority: env == 'prod' ? 'https://idx.linkhealth.com/auth/realms/developer-platform/' : 'https://idx-stage.linkhealth.com/auth/realms/developer-platform/',
            redirectUrl:  `${window.location.origin}/callback`,
            postLogoutRedirectUri: `${window.location.origin}/logout`,
            clientId: env == 'prod' ? 'provider_attestation-optum_care_west-prod' : 'provider_attestation-optum_care_west-non_prod',
              scope: 'openid profile offline_access roles', 
              responseType: 'code',
              silentRenew: true,
              useRefreshToken: true,
              renewTimeBeforeTokenExpiresInSeconds: 30,
              autoCleanStateAfterAuthentication: false,
              triggerAuthorizationResultEvent: true,
              secureRoutes: ['https://dev-providerattestation.optum.com/portal-service', 'http://localhost:7197','https://qa-providerattestation.optum.com/portal-service','https://providerattestation.optum.com/portal-service']                 
        };
    }));
}

export const runtimeConfigLoaderFactory = (runtimeConfigLoaderService: RuntimeConfigLoaderService): StsConfigLoader => {
    return new StsConfigHttpLoader(getOpenIdConfiguration(runtimeConfigLoaderService));
}

@NgModule({
    imports: [AuthModule.forRoot({
        loader: {
            provide: StsConfigLoader,
            useFactory: runtimeConfigLoaderFactory,
            deps: [RuntimeConfigLoaderService],
        },
      }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
      ],
    exports: [AuthModule],
})

export class AuthConfigModule {
    constructor(private runtimeConfigLoaderService: RuntimeConfigLoaderService) { }
}