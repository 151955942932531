<lte-layout-wrapper [isAdminLayoutType]="true">
  
        <lte-layout-header logoLink="/" headerTitle="Provider Attestations" [isAdminLayoutType]="true">
        <lte-layout-header-logo>Provider Attestation</lte-layout-header-logo>
        <lte-layout-header-logo-mini><b>PA Test</b></lte-layout-header-logo-mini>
        <!-- <app-header-inner> </app-header-inner> -->
      </lte-layout-header> 
      <div class="col-md-12 bar"></div>
      <lte-layout-content [isAdminLayoutType]="true">
          <div class="pt-0 mt-0">   
      <div class="container">
        <div lte-layout-content-before-header>
          <!-- <div *ldpLoadingPage="{ checkPendingHttp: true, checkPendingRoute: true }"> -->
            <!-- <ldp-material-bar></ldp-material-bar> -->
          <!-- </div> -->
        </div>
        <app-provider-list></app-provider-list>
      </div>
      </div>
        <!-- <router-outlet></router-outlet> -->
      </lte-layout-content>
      <lte-layout-footer [isUserLayout]="true">
        <lte-layout-footer-left>
          <span>{{copyright}}</span>
        </lte-layout-footer-left>
        <lte-layout-footer-right> </lte-layout-footer-right>
      </lte-layout-footer>    
  </lte-layout-wrapper>