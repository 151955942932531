import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular//platform-browser';
import { SidebarLeftModule } from './sidebar-left/sidebar-left.module'
import { WrapperModule } from './wrapper/wrapper.module';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { ContentModule } from './content/content.module';

import { LayoutService } from './layout.service';
import { WrapperService } from './wrapper/wrapper.service';
import { HeaderService } from './header/header.service';
import { FooterService } from './footer/footer.service';
import { RoutingService } from './routing.service';
import { layoutProvider } from './layout.provider';
import { LayoutState } from './layout-store';

@NgModule({
  imports: [CommonModule, RouterModule,BrowserModule],
  exports: [    
    HeaderModule,   
    WrapperModule,
    FooterModule,
    ContentModule,
    SidebarLeftModule
  ],
  providers: [  
    RoutingService,  
    WrapperService,    
    HeaderService,
    FooterService   
  ],
  
})
export class LayoutModule {
  /**
   * @method constructor
   * @param parentModule [description]
   */
  constructor(@Optional() @SkipSelf()  parentModule: LayoutModule) {
    if (parentModule) {
      throw new Error(
        'LayoutModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  /**
   * [forRoot description]
   * @method forRoot
   * @param  layoutConfig [description]
   * @return [description]
   */
  static forRoot(layoutConfig: LayoutState): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [...layoutProvider(layoutConfig), LayoutService],
    };
  }
}
