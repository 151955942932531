import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, tap, throwError } from "rxjs";
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { ICommandResult } from "src/app/app-store/entities/common";
import { IVendorRegistration, IRegistrationStatus, IRegistrationChallengeResult } from "src/app/app-store/entities/registration";
import { IProviderIdentity } from "src/app/app-store/entities/user";

@Injectable({
    providedIn: 'root'
  })
  
  export class RegistrationService {
    baseUrl!: string;
    providerList!:any;
    constructor(
      private http: HttpClient,
      private runtimeConfigLoaderService: RuntimeConfigLoaderService
    ) {
      this.baseUrl = this.runtimeConfigLoaderService.getConfigObjectKey('API_URL');
    }

    getVendorRegistration(regCode:string, tokenParse:string):Observable<IVendorRegistration>{
      return this.http.get<any>(`${this.baseUrl}/registration/vendor/`+ regCode, { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }

    getRegistrationStatus(regCode:string, tokenParse:string):Observable<IRegistrationStatus>{
      return this.http.get<any>(`${this.baseUrl}/registration/userstatus/`+ regCode, { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }

    verifyRegistration(tin:string, npi:string, regCode:any, tokenParse:string):Observable<IRegistrationChallengeResult>{
      return this.http.post<any>(`${this.baseUrl}/registration/verifyuser/${tin}/${npi}/${regCode}`,"", { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }

    registerPrevious(tin:string, npi:string, regCode:any, tokenParse:string):Observable<ICommandResult>{
      return this.http.post<any>(`${this.baseUrl}/registration/registerprevious/${tin}/${npi}/${regCode}`,"", { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }
    
    assignRole(tin:string, npi:string, regCode:any, role:string, tokenParse:string):Observable<ICommandResult>{
      return this.http.post<any>(`${this.baseUrl}/registration/assignrole/${tin}/${npi}/${regCode}/${role}`,"", { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }

    saveSelectedProviders(tin:string, npi:string, regCode:any, selectedProviders:IProviderIdentity[], tokenParse:string):Observable<ICommandResult>{
      return this.http.post<any>(`${this.baseUrl}/registration/saveselected/${tin}/${npi}/${regCode}`,selectedProviders, { headers:new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`)});       
    }
  }