<div
  class="modal fade"
  [ngStyle]="{ display: isOpened ? 'block' : 'none' }"
  #modalRoot
  (keydown.esc)="closeOnEscape ? close() : 0"
  (click)="closeOnOutsideClick ? close() : 0"  
>
  <div class="vertical-alignment-helper">
    <div class="modal-dialog vertical-align-center" (click)="preventClosing($event)">
      <div class="modal-content" [style.width]="contentWidth">
        <div class="modal-header">
          <button type="button" *ngIf="!hideCloseButton" class="close" (click)="close()" title="close">
            <span aria-hidden="true">×</span>
          </button>
          <h4 *ngIf="title" class="modal-title">{{ title }}</h4>
          <div class="modal-title">
            <ng-template [ngTemplateOutlet]="modalHeader?.templateRef!"></ng-template>
          </div>
        </div>
        <div class="modal-body">
          <ng-template [ngTemplateOutlet]="modalBody?.templateRef!"></ng-template>
        </div>
        <div class="modal-footer">
          <ng-template [ngTemplateOutlet]="modalFooter?.templateRef!"></ng-template>
          <div class="pull-left text-warning" *ngIf="isFormModal">
            <small>{{ footerText }}</small>
          </div>
          <div [ngClass]="buttonAlign">
            <button
              *ngIf="cancelButtonLabel"
              type="button"
              class="btn btn-default btn-secondary"
              data-dismiss="modal"
              (click)="close()"
            >
              {{ cancelButtonLabel }}
            </button>
            <button
              *ngIf="displayBackButton"
              type="button"
              class="btn btn-default btn-secondary"
              data-dismiss="modal"
              (click)="back()"
            >
              {{ backButtonLabel }}
            </button>
            <button
              *ngIf="submitButtonLabel && displaySubmitButton"
              type="button"
              class="btn btn-primary ml-1"
              (click)="submit()"
              [disabled]="disableSubmitButton"
            >
              {{ submitButtonLabel }}
            </button>
            <button
              [disabled]="disableReviewButton"
              *ngIf="displayReviewButton"
              type="button"
              class="btn btn-primary"
              (click)="review()"
            >
              {{ reviewButtonLabel }}
            </button>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</div>
