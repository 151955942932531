<lte-layout-wrapper>
  <lte-layout-header logoLink="/" headerTitle="Provider Attestation" [isAdminLayoutType]="false">
    <lte-layout-header-logo>Provider Attestation</lte-layout-header-logo>    
  </lte-layout-header>

  <lte-layout-content>
    <div>
      <router-outlet></router-outlet>
    </div>
  </lte-layout-content>
  <app-footer></app-footer>
</lte-layout-wrapper>