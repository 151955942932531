import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperModule } from '../libs/wrapper/wrapper.module';
import { HeaderModule} from '../libs/header/header.module';
import { SidebarLeftModule } from '../libs/sidebar-left/sidebar-left.module'
import {AnimationModule} from '../libs/animation/animation.module';
import { ModalModule } from '../libs/modal/modal.module';


const lteModules: any[] = [
    AnimationModule,
    HeaderModule,
    WrapperModule ,
    RouterModule,    
    CommonModule,
    SidebarLeftModule,
    ModalModule,    
]

const components: any[] = [
];
// const layouts: any[] = [ AdminLayoutComponent];

@NgModule({
    declarations: [...components],
    imports: [...lteModules],     
    exports: [...lteModules],        
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers:[]
  })
export class SharedModule {}