import { Directive, ElementRef, HostListener, Input } from '@angular/core';
 
@Directive({
  selector: '[keyBoardEventHandler]'
})
export class EventBindingDirective {
  @Input() keyBoardEventHandler: { [key: string]: () => void };
 
  constructor(private el: ElementRef) {}
 
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    const callback = this.keyBoardEventHandler[event.key];
    if (callback) {
      event.preventDefault();
      callback();
    }
  }
}