import { ElementSchemaRegistry } from '@angular/compiler';
import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly router = inject(Router);
  forbiddenUrl: string = "/forbidden";
  isPdiUser: boolean = false;
  isPdiAdmin: boolean = false;
  
  constructor(private runtimeConfigLoaderService: RuntimeConfigLoaderService) {}

  isAuthenticatedUser(): boolean | UrlTree {
    var retval: any = this.router.parseUrl(this.forbiddenUrl);
    if (this.checkUserAuthenticated())
    {
          retval = true;
    }
    return retval;
  }

  isPdiAdminRole(): boolean | UrlTree {
    var retval: any = this.router.parseUrl(this.forbiddenUrl);

    if (this.checkUserAuthenticated())
    {
      if (this.isPdiAdmin)
        retval = true;
    }
    return retval;
  }

  isPdiUserRole(): boolean | UrlTree {
    var retval: any = this.router.parseUrl(this.forbiddenUrl);

    if (this.checkUserAuthenticated())
    {
        if (this.isPdiAdmin || this.isPdiUser)
          retval = true;
    }
    return retval;
  }

  checkUserAuthenticated(): boolean {
    var retval: boolean = false;

    this.oidcSecurityService.isAuthenticated$.subscribe( ({isAuthenticated})  => {
      if (isAuthenticated) {
        this.oidcSecurityService.getPayloadFromAccessToken().subscribe((result)=> {
          let roles = result.roles;
          //debugger;
          let env = this.runtimeConfigLoaderService.getConfigObjectKey('ENV');
          console.log('auth service env:' + env);
          if (env == 'prod') {
            if (roles != undefined && roles.includes('OPTUMCARE_PDI_USER'))
              this.isPdiUser = true;
            if (roles!= undefined && roles.includes('OPTUMCARE_PDI_ADMIN'))
              this.isPdiAdmin = true;
          } 
          else {
            if (roles != undefined && roles.includes('OPTUMCARE_PDI_NONPROD_USER'))
              this.isPdiUser = true;
            if (roles!= undefined && roles.includes('OPTUMCARE_PDI_NONPROD_ADMIN'))
              this.isPdiAdmin = true;
          }
        });
        retval = true;
      }
    });
    return retval;
  }

  getAccessToken(): string {
    var token: string = "";
    this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
      let token = accessToken;
      //console.log("getAccessToken - AuthToken: " + token);
    });
    return token;
  }
  
logout(): void {
    this.oidcSecurityService.logoff().subscribe((result) => console.log(result));
  }
}
