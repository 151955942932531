import { AbstractControl } from '@angular/forms';

export class TextValidators {
  static notEmpty(control: AbstractControl) {
    if (control && control.value && control.value!='') {
      return null;
    }
    return { empty: true };
  }

  static emailValidator(control: AbstractControl) {
    //const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const ctrlRegexp: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
    if (control && control.value && control.value.trim().length > 0) {
        if(ctrlRegexp.test(control.value))
         return { invalidEmail: true };
        else
         return null;
    }
    return null;
  }
}
