<div class="container mt-5">
    <div class="row">
        <div class="col-xs-12 col-md-12">                                                                                               
            <div class="mt-3 p-3 bg-white">
                <div *ngIf="isAttestationPeriodClosed ==true" class="alert alert-warning">
                    This attestation link is no longer active. Please wait for the next attestation period. A new link from OptumCAAttestations&#64;optum.com will be sent to your email. Please contact us at <a class="alert-warning" href="mailto:{{contactEmail}}?subject=Optum Provider Attestation web portal assistance">{{contactEmail}}</a> for more information.
                </div>
                <ngx-datatable #table class="bootstrap" [columnMode]="ColumnMode.force"
                    [headerHeight]="50" rowHeight="auto" [rows]="providers" 
                    [sorts]="[{ prop: 'fullName', dir: 'asc' }, { prop: 'vendorName', dir: 'asc' }]">
                    <ngx-datatable-column [sortable]="false" name="Name" prop="fullName" [width]="200">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            <a href=javascript:void(0) [ngClass]="{disableLink: isAttestationPeriodClosed==true || row.hasException == true}" (click)="isAttestationPeriodClosed==true ?'':showDetails(row)"><span class="span-textoverflow">{{value}}</span></a>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="false" name="NPI" prop="npi"></ngx-datatable-column>
                    <ngx-datatable-column [sortable]="false" name="Organization" prop="vendorName"></ngx-datatable-column>
                    <ngx-datatable-column [sortable]="false" name="TIN" prop="tin"></ngx-datatable-column>
                    <ngx-datatable-column [sortable]="false" name="Completed" prop="attested">
                        
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            <span *ngIf="value==true && row.hasException==false" class="alert-success">Yes</span>
                            <span *ngIf="value==false && row.hasException==false" class="alert-danger">No</span>
                            <span *ngIf="row.hasException==true" class="alert-warning" [ngbTooltip]="tooltipTemplate" placement="top" container="body" [closeDelay]="700" >Invalid</span>
                            <ng-template #tooltipTemplate>
                               <span *ngIf="row.hasException==true">{{row.exceptionDescription}}, please reach out to <a href="mailto:{{contactEmail}}?subject=Optum Provider Attestation web portal assistance">{{contactEmail}}</a> </span>
                            </ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>