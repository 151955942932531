import { Component, OnInit } from '@angular/core';
import { CurrentStorageService } from 'src/app/core/services/current-storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {
  message: string = '';
  userType: string = "";
  copyright: string = environment.copyright; 
constructor(
  private router: Router,
  private currentStorageService: CurrentStorageService
)
  {}

  ngOnInit(): void {
    this.userType = this.currentStorageService.userType;
    //console.log("userType: " + this.userType);

    //this.currentStorageService.userType = "";
    this.currentStorageService.userName = "";
    this.currentStorageService.userFullName = ""; 
  }

  signIn() {
   
    //console.log("Sign in userType: " + this.userType);
    if (this.userType != undefined && this.userType == "pdi" )
      this.router.navigate(['login/pdi']); 
    else
      this.router.navigate(['login']);
  }
}
