import { AbstractControl } from '@angular/forms';

export class SpecialCharacterValidators {
  static validateSpecialCharacter(control: AbstractControl) {
    //allowing #,- and comma
    const ctrlRegexp: RegExp = /[!@$%^&*()_+\=\[\]{};':"\\|<>\/?]/;
    if (control && control.value && control.value.trim().length > 0) {
        if(ctrlRegexp.test(control.value))
         return { invalidChar: true };
        else
         return null;
    }
    return null;
  }

  static noSpecialCharacter(control: AbstractControl) {
    //const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const ctrlRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (control && control.value && control.value.trim().length > 0) {
        if(ctrlRegexp.test(control.value))
         return { invalidChar: true };
        else
         return null;
    }
    return null;
  }

  
}