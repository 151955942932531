import { SidebarLeftToggleDirective } from './sidebar-left.directive';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AnimationModule } from '../animation/animation.module';
import { SidebarLeftComponent } from './sidebar-left.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, RouterModule, AnimationModule],
  exports: [SidebarLeftComponent],
  declarations: [SidebarLeftToggleDirective, SidebarLeftComponent]
})
export class SidebarLeftModule {}
