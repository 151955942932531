import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentStorageService {
  private storage = sessionStorage;

  public get userId(): string {
    return this.storage.getItem('userId')!;
  }

  public set userId(val: string) {
    if (val == "")
      this.storage.removeItem('userId');
    else
      this.storage.setItem('userId', val);
  }
  public get userName(): string {
    return this.storage.getItem('userName')!;
  }

  public set userName(val: string) {
    if (val == "")
      this.storage.removeItem('userName');
    else
      this.storage.setItem('userName', val);
  }

  public set NPI (val: string) {
    this.storage.setItem('NPI', val);
  }

  public get NPI(): string {
    return this.storage.getItem('NPI')!;
  }

  public set TIN (val: string) {
    this.storage.setItem('TIN', val);
  }

  public get TIN(): string {
    return this.storage.getItem('TIN')!;
  }

  public set AttestationPeriod (val: string) {
    this.storage.setItem('AttestationPeriod', val);
  }

  public get AttestationPeriod(): string {
    return this.storage.getItem('AttestationPeriod')!;
  }

  public set RegCode (val: any) {
    this.storage.setItem('RegCode', val);
  }

  public get RegCode(): any {
    return this.storage.getItem('RegCode')!;
  }

  public set SelectedProviderId (val: any) {
    this.storage.setItem('SelectedProviderId', val.toString());
  }
  
  public get SelectedProviderId(): any {
    return this.storage.getItem('SelectedProviderId')!;
  }

  public set userFullName (val: any) {
    if (val == "")
      this.storage.removeItem('userFullName');
    else
      this.storage.setItem('userFullName', val.toString());
  }
  
  public get userFullName (): any {
    return this.storage.getItem('userFullName')!;
  }

  public set userType (val: any) {
    if (val == "")
      this.storage.removeItem('userType');
    else
      this.storage.setItem('userType', val.toString());
  }
  
  public get userType (): any {
    return this.storage.getItem('userType')!;
  }
}
