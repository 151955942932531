import { Subscription } from 'rxjs';

/**
 * [throttle description]
 * @method throttle
 * @param callback [description]
 * @param delay    [description]
 * @return [description]
 */
export function throttle(
  callback: Function,
  delay: number
): (args: Array<any>) => void {
  let timeout: any = null;
  return (...args) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        callback.call(timeout, ...args);
        timeout = null;
      }, delay);
    }
  };
}

/**
 * [removeSubscriptions description]
 * @method removeSubscriptions
 */
export function removeSubscriptions(subscriptions: any): Array<Subscription> {
  if (subscriptions) {
    subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
  return [];
}

/**
 * [removeListeners description]
 * @method removeListeners
 */
export function removeListeners(listeners: any): Array<Function> {
  if (listeners) {
    listeners.forEach((listener: Function) => {
      listener();
    });
  }
  return [];
}
