<!-- <footer class="main-footer"> -->
<div class="container">
  <div class="row">
    <div class="col-12">
      <footer [class.main-footer]="!isUserLayout" [class.main-footerUser]="isUserLayout">
        <ng-template [ngTemplateOutlet]="footerRightComponent.templateRef"></ng-template>
        <ng-template [ngTemplateOutlet]="footerLeftComponent.templateRef"></ng-template>
      </footer>
    </div>
  </div>
</div>