<div class="container-fluid">
  <div class="row">
    <div class="col-xs-1 col-md-3"></div>
    <div class="col-xs-10 col-md-6">
      <div *ngIf="step=='verify'" class="mt-3 mb-2">
        <div class="box box-default bg-white">
          <div class="box-header">
            <div class="text-center pt-4 pb-4">
              <span class="optum-logo">
                <img src="../../../../assets/img/optum.svg" class="" alt="Optum" width="100" />
              </span>
              <span class="pl-3">Provider Attestation</span>
            </div>
          </div>
          <div class="box-body">
            <h5 class="text-optum-color-dark text-bold pl-3 pr-3">Registration</h5>
            <p class="pl-3 pr-3">Enter the TIN and NPI of the provider you are attesting for. If you are attesting for mutiple providers,
              additional NPIs can be added later.</p>
            <form [formGroup]="formGroup" class="pl-3 pr-3" [keyBoardEventHandler]="keyHandlers">
              <div *ngIf="error" class="alert alert-danger mt-2 mb-2 pl-3 pr-3">
                {{ errorMessage }}
              </div>
              <div class="form-group"
                [ngClass]="{'has-error': formGroup.controls['txtTIN'].touched && formGroup.controls['txtTIN'].errors}">
                <label>TIN</label>
                <input type="text"  class="form-control form-rounded"  placeholder=""  formControlName="txtTIN"  (keypress)="keyPressValidation($event)"/>
              </div>
              <div class="form-group"
                [ngClass]="{'has-error': formGroup.controls['txtNPI'].touched && formGroup.controls['txtNPI'].errors}">
                <label>NPI</label>
                <input type="text" class="form-control bg-white form-rounded" placeholder="" formControlName="txtNPI" />
              </div>
              <div class="text-center pt-3 pb-3">
                <button type="button" (click)="verify()" class="btn btn-primary rounded-pill"><span
                    class="pl-3 pr-3">Verify</span></button>
              </div>
            </form>
          </div>
          <div class="box-footer text-center">
            <span>{{copyright}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="step=='previous'" class="mt-3 mb-2">
        <div class="box box-default bg-white">
          <div class="box-header">
            <div class="text-center pt-4 pb-4">
              <span class="optum-logo">
                <img src="../../../../assets/img/optum.svg" class="" alt="Optum" width="100" />
              </span>
              <span class="pl-3">Provider Attestation</span>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-12">
                <div class="custom-control">
                  <h5 class="text-optum-color-dark text-bold">Registration</h5>
                  <p>Have there been changes to clinicians or demographics since the last time you attested?</p>
                  <div class="col-xs-12 col-lg-8 border form-control form-rounded mt-1 mb-3 p-2">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"
                        [(ngModel)]="registerSameDetails" [value]="false">
                      <label class="custom-control-label" for="customRadio1">Yes</label>
                    </div>
                  </div>
                  <div class="col-xs-12 col-lg-8 border form-control form-rounded mt-3 mb-3 p-2">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"
                        [(ngModel)]="registerSameDetails" [value]="true">
                      <label class="custom-control-label" for="customRadio2">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-0 ml-2">
              <div class="col-md-11 mb-0 ml-1">
                <div class="col-md-3 pl-0 optum-border-bottom"><span class="labelHeader">Providers</span></div>
                <div class="col"></div>
              </div>
            </div>
            <div class="row ml-2 mr-2">
              <div class="col-md-12">
                <ngx-datatable #providerHistorytable class="bootstrap table table-striped"
                  [columns]="providerHistoryColumns" [columnMode]="columnMode.force" [headerHeight]="50"
                  [rowHeight]="50" [rows]="providerHistoryData" [scrollbarV]="true" style="height: 300px;">
                  <ngx-datatable-column name="Name" prop="fullName" [sortable]=false></ngx-datatable-column>
                  <ngx-datatable-column name="NPI" prop="npi" [sortable]=false></ngx-datatable-column>
                  <ngx-datatable-column name="Organization" prop="vendorName" [sortable]=false></ngx-datatable-column>
                  <ngx-datatable-column name="TIN" prop="tin" [sortable]=false></ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pt-3 pb-3">
                <button type="button" (click)="sameDetails()"
                  class="btn btn-primary rounded-pill pull-right">Continue</button>
              </div>
            </div>
          </div>
          <div class="box-footer text-center">
            <span>{{copyright}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="step=='role'" class="mt-3 mb-2">
        <div class="box box-default bg-white">
          <div class="box-header">
            <div class="text-center pt-4 pb-4">
              <span class="optum-logo">
                <img src="../../../../assets/img/optum.svg" class="" alt="Optum" width="100" />
              </span>
              <span class="pl-3">Provider Attestation</span>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-12">
                <div class="custom-control">
                  <h5 class="text-optum-color-dark text-bold">Select your role</h5>
                  <div class="col-xs-12 col-lg-8 border form-control form-rounded mt-1 mb-3 p-2">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio3" name="customRadioRole" class="custom-control-input"
                        [(ngModel)]="userRole" value="administrative">
                      <label class="custom-control-label" for="customRadio3">Administrative</label>
                    </div>
                  </div>
                  <div class="col-xs-12 col-lg-8 border form-control form-rounded mt-1 mb-3 p-2">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio4" name="customRadioRole" class="custom-control-input"
                        [(ngModel)]="userRole" value="provider">
                      <label class="custom-control-label" for="customRadio4">Provider</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pt-3 pb-3 text-center">
                <button *ngIf="hideRoleBack==false" type="button" (click)="roleSelect('back',false)"
                  class="btn btn-secondary-optum rounded-pill mr-1"><span class="pl-2 pr-2">Back</span></button>
                <button type="button" (click)="roleSelect('next',false)"
                  class="btn btn-primary rounded-pill ml-1">Continue</button>
              </div>
            </div>
          </div>
          <div class="box-footer text-center">
            <span>{{copyright}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="step=='select'" class="mt-3 mb-2">
        <div class="box box-default bg-white">
          <div class="box-header">
            <div class="text-center pt-4 pb-4">
              <span class="optum-logo">
                <img src="../../../../assets/img/optum.svg" class="" alt="Optum" width="100" />
              </span>
              <span class="pl-3">Provider Attestation</span>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-12 text-center">
                <h5 class="text-optum-color-dark text-bold">{{verifiedVendorName}}&nbsp;{{verificationTin}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <p>You have been registered for provider <span
                    class="text-optum-color-dark text-bold">({{verificationNpi}})</span></p>
                <p>Optionally, add additional providers below.</p>
              </div>
            </div>
            <div class="row ml-2">
              <div class="col-11 ml-1">
                <div class="col-3 pl-0 optum-border-bottom"><span class="pl-0 labelHeader">All&nbsp;Providers</span></div>
                <div class="col"></div>
              </div>
            </div>
            <div class="row ml-2 pl-3">
              <div class="col-11">
                <div class="form-group">
                  <div class="pull-left">
                    <label class="form-control-name pt-2">Total&nbsp;Count:&nbsp;{{providerSelectData.length}}</label>
                  </div>
                  <div class="pull-right">
                    <div class="input-group">
                      <input type="text" class="form-control form-rounded-1" placeholder="Search ..." (keyup)="updateSelectFilter($event)" />
                      <div class="input-group-append">
                        <span class="input-group-text form-rounded-1 bg-white"><i class="fa fa-search text-optum-color-orange"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ml-2 mr-2">
              <div class="col-12 ">
                <ngx-datatable #selecttable class="bootstrap table table-striped" [columns]="selectColumns"
                  [columnMode]="columnMode.force" [headerHeight]="50" [rowHeight]="50" [rows]="providerSelectData"
                  [selected]="selected" [selectionType]="selection" (select)='onSelect($event)' [scrollbarV]="true" 
                  style="height: 300px;">
                  <ngx-datatable-column [width]="40" [sortable]="false" [draggable]="false" [resizeable]="false"
                    [canAutoResize]="false" [headerCheckboxable]="true" [checkboxable]="true" headerClass="text-center"
                    cellClass="text-center">
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Name" prop="fullName" [sortable]=false></ngx-datatable-column>
                  <ngx-datatable-column name="NPI" prop="npi" [sortable]=false></ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-3 pb-3 text-center">
                <button *ngIf="hideSelectBack==false" type="button" (click)="providerSelect('back')"
                  class="btn btn-secondary-optum rounded-pill mr-1"><span class="pl-2 pr-2">Back</span></button>
                <button type="button" (click)="providerSelect('next')"
                  class="btn btn-primary rounded-pill ml-1">Continue</button>
              </div>
            </div>
          </div>
          <div class="box-footer text-center">
            <span>{{copyright}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="step=='confirm'" class="mt-3 mb-2">
        <div class="box box-default bg-white">
          <div class="box-header">
            <div class="text-center pt-4 pb-4">
              <span class="optum-logo">
                <img src="../../../../assets/img/optum.svg" class="" alt="Optum" width="100" />
              </span>
              <span class="pl-3">Provider Attestation</span>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-12 text-center">
                <h5 class="text-optum-color-dark text-bold">{{verifiedVendorName}}&nbsp;{{verificationTin}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <p>You have been registered for provider <span
                    class="text-optum-color-dark text-bold">{{verificationNpi}}</span></p>
                <p>Confirm your selection below.</p>
              </div>
            </div>
            <div class="row mb-0 ml-2">
              <div class="col-3"><span class="text-optum-color-dark text-bold">All&nbsp;Providers</span></div>
              <div class="col-4 text-left">
                <div class="pl-0 pb-2 optum-border-bottom">
                  <span class="text-optum-color-dark text-bold">Selected&nbsp;Providers</span>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <div class="row ml-2 mr-2">
              <div class="col-md-12 ">
                <ngx-datatable #confirmtable class="bootstrap table table-striped" [columns]="confirmColumns"
                  [columnMode]="columnMode.force" [headerHeight]="50" [rowHeight]="50" [rows]="providerConfirmData"
                  [selected]="selected" [selectionType]="selection" (select)='onSelect($event)' [scrollbarV]="true" 
                  style="height: 300px;">
                  <ngx-datatable-column [width]="40" [sortable]="false" [draggable]="false" [resizeable]="false"
                    [canAutoResize]="false" [headerCheckboxable]="true" [checkboxable]="true" headerClass="text-center"
                    cellClass="text-center">
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Name" prop="fullName" [sortable]=false></ngx-datatable-column>
                  <ngx-datatable-column name="NPI" prop="npi" [sortable]=false></ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pt-3 pb-3 text-center">
                <button type="button" (click)="providerConfirm('back')"
                  class="btn btn-secondary-optum rounded-pill mr-1"><span class="pl-2 pr-2">Back</span></button>
                <button type="button" (click)="providerConfirm('next')"
                  class="btn btn-primary rounded-pill ml-1">Confirm</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-1 col-md-3"></div>
  </div>
</div>