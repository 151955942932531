import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy,Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { HeaderService } from '../header/header.service';
import { FooterService } from '../footer/footer.service';
import { removeSubscriptions } from '../helpers';
import { LayoutStore } from '../layout-store';


@Component({
  selector: 'lte-layout-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent implements OnInit {
  
  @Input() isAdminLayoutType!: boolean;
  public description!: string;
  public header!: string;
  public heightStyle!: any;
  public sidebarLeftHeight!: number;
  public windowInnerHeight!: number;

  private layout!: string;
  private titleTag!: string;
  private navigationEnd!: boolean;
  private subscriptions: any[] = [];

  @ViewChild('contentInnerElement', { static: true })
  private contentInnerElement!: ElementRef;

  /**
   * @method constructor
   * @param layoutStore   
   * @param titleService
   * @param elementRef
   * @param changeDetectorRef   
   * @param headerService
   * @param footerService
   * @param router
   */
  constructor(
    private layoutStore: LayoutStore,    
    private titleService: Title,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,   
    private headerService: HeaderService,
    private footerService: FooterService,
    private router: Router
  ) {}

  /**
   * @method ngOnInit
   */
  ngOnInit() {
    this.titleTag = this.titleService.getTitle();
     this.subscriptions.push(
      this.router.events.subscribe((routeEvent: any) => {
        if (routeEvent instanceof NavigationStart) {
          this.navigationEnd = false;
        }
        if (routeEvent instanceof NavigationEnd) {
          this.navigationEnd = true;
          this.setContentMinHeight();
        }
      })
    );

    this.subscriptions.push(
      this.layoutStore.sidebarLeftElementHeight.subscribe((value: number) => {
        this.sidebarLeftHeight = value;
        this.setContentMinHeight();
      })
    );

    this.subscriptions.push(
      this.layoutStore.layout.subscribe((value: string) => {
        this.layout = value;
        this.setContentMinHeight();
      })
    );

    this.subscriptions.push(
      this.layoutStore.windowInnerHeight.subscribe((value: number) => {
        this.windowInnerHeight = value;
        this.setContentMinHeight();
      })
    );
    this.heightStyle = this.windowInnerHeight + 102;
  }

  /**
   * @method ngOnDestroy
   */
  ngOnDestroy() {
    this.subscriptions = removeSubscriptions(this.subscriptions);
  }

  /**
   * [scrollHeight description]
   * @method scrollHeight
   * @return [description]
   */
  public get scrollHeight(): number {
    return this.contentInnerElement.nativeElement.scrollHeight;
  }

  /**
   * [getTitle description]
   * @method getTitle
   * @param title [description]
   * @return [description]
   */
  private getTitle(title: string): string | undefined {
    return title ? `${title} | ${this.titleTag}` : this.titleTag;
  }

  /**
   * [setMinHeight description]
   * @method setMinHeight
   */
  private setContentMinHeight(): void {
    if (this.navigationEnd) {
      let heightStyle = 0;
      const headerFooterOffsetHeight = this.headerService.offsetHeight + this.footerService.offsetHeight;

      if (this.layout === 'fixed' && this.windowInnerHeight) {
        heightStyle = this.windowInnerHeight - this.footerService.offsetHeight;
      } else if (this.windowInnerHeight && this.sidebarLeftHeight) {
        const sidebarRight = 0;

        heightStyle = Math.max(
          this.windowInnerHeight - headerFooterOffsetHeight,
          this.sidebarLeftHeight - this.headerService.offsetHeight,
          sidebarRight
        );
      }

      if (heightStyle && heightStyle !== this.heightStyle) {
        if (this.scrollHeight > heightStyle) {
          heightStyle = 0;
        }
        this.heightStyle = heightStyle + 102;
        this.changeDetectorRef.detectChanges();
      }
    }
  }
}
