import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Idle, EventTargetInterruptSource } from '@ng-idle/core';
import { ModalComponent } from 'src/app/libs/modal/modal.component';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import * as moment from 'moment';
import 'moment-duration-format';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Provider Attestation';

  @ViewChild('sessionTimeoutModal', { static: true })
  sessionTimeoutModal!: ModalComponent;
  timeoutMessage = '';
  timeoutRemainingTime = '';
  timedOut = false;
  timeOutWarning = false;
  lastPing?: Date;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    private authService: AuthService
  ) {
      this.setSessionTimeout();
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe(({ isAuthenticated, userData, accessToken }) => {
        console.log('app authenticated', isAuthenticated);
        console.log(`Current access token is '${accessToken}'`);
        this.resetSessionTimer();
      });
  }

  resetSessionTimer() {
    this.idle.watch();
    this.timeoutMessage = '';
    this.timeoutRemainingTime = '';
    this.timedOut = false;
  }

  setSessionTimeout() {
    let sessiontimeout: number = environment.timeout; //27
    let sessiontimeoutwarning: number = environment.timeoutWarning; //3

    this.idle.setIdle(sessiontimeout * 60);
    this.idle.setTimeout(sessiontimeoutwarning * 60);
    this.idle.setInterrupts([
      new EventTargetInterruptSource(document, 'click'),
      new EventTargetInterruptSource(document, 'dblclick'),
      new EventTargetInterruptSource(document, 'keypress'),
    ]);
    window.addEventListener("storage", this.storageEventListener.bind(this));

    this.idle.onIdleEnd.subscribe(() => {
      this.cd.detectChanges();
    });

    this.idle.onTimeout.subscribe(() => {
      window.localStorage.setItem('logout-event', Math.random().toString());
      this.sessionTimeoutModal.close();
      if (this.authService.isAuthenticatedUser())
        this.authService.logout();
      else  {
        this.router.navigate(['logout']);
      }
    });

    this.idle.onIdleStart.subscribe(() => {
      //console.log("current path: " + this.location.path());
      if (this.location.path() != '/logout') {
        this.sessionTimeoutModal.open();
      }
    });

    this.idle.onTimeoutWarning.subscribe((countdown: any) => {
      var duration = moment.duration(countdown, 'seconds');
      var formatted = duration.format("m:ss", { trim: false });

      this.timeoutMessage = "Click \"Stay Active\" to continue your session or choose \"Logout\" to end it.";
      this.timeoutRemainingTime = formatted;
    });
  }

  timeoutWarningSubmit(): void {
    this.resetSessionTimer();
    this.sessionTimeoutModal.close();
  }

  logoutSession(): void {
    this.resetSessionTimer();
    this.sessionTimeoutModal.close();
    window.localStorage.setItem('logout-event', Math.random().toString());
    if (this.authService.isAuthenticatedUser()) {
      this.authService.logout();
    }
    else {
      this.router.navigate(['logout']);
  }
  }
  
  //to logout user from all tabs using storage events
  //will logout user from all tabs when session is over & on logout click from popup
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        if (this.authService.isAuthenticatedUser()){
          this.authService.logout();
        }
        else {
          this.router.navigate(['logout']);
        }
      }
      } 
  }
  private clearEventListeners(): void {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.clearEventListeners();
  }
}
