import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit(): void {
    let hint = 'optum-id'; //kc_idp_hint - One Healthcare ID (OHID)	optum-id
    let userType = this.route.snapshot.params['usertype'];
    if (userType != undefined && userType == "pdi" ) {
      hint = 'uhg-ms-id'; //kc_idp_hint - MSID	uhg-ms-id
    } 
    this.oidcSecurityService.setState(userType).subscribe((result) => {
      this.oidcSecurityService.authorize(undefined, { customParams: { kc_idp_hint: hint }});
    });
  }

  
}
