import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
  } from '@angular/core';
import { HeaderService } from './header.service';
import { CurrentStorageService } from 'src/app/core/services/current-storage.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

  /**
 * Header Logo
 */
@Component({
    selector: 'lte-layout-header-logo',
    template: '<ng-template #templateRef><ng-content></ng-content></ng-template>',
  })
  export class HeaderLogoComponent {
    @ViewChild('templateRef', { static: true })
    public templateRef!: TemplateRef<any>;
  }

  /**
 * Header Logo Mini
 */
@Component({
    selector: 'lte-layout-header-logo-mini',
    template: '<ng-template #templateRef><ng-content></ng-content></ng-template>',
  })
  export class HeaderLogoMiniComponent {
    @ViewChild('templateRef', { static: true })
    public templateRef!: TemplateRef<any>;
  }
  
  /**
   * Header
   */
  @Component({
    selector: 'lte-layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
  })
  export class HeaderComponent implements AfterViewInit, OnDestroy,OnInit {
    private subscriptions: any[] = [];
    userName!: string;
    userFullName!: string;
    isPdiAdmin!: boolean;

    @Input() isSidebarLeftToggle = true;
    @Input() isSidebarRightToggle = true;
    @Input() logoLink: string | any[] = '/';
    @Input() headerTitle!: string;
    @Input() isAdminLayoutType!: boolean;
    
    @ContentChild(HeaderLogoComponent, { static: false })
    public headerLogoComponent!: HeaderLogoComponent;
    @ContentChild(HeaderLogoMiniComponent, { static: false })
    public headerLogoMiniComponent!: HeaderLogoMiniComponent;

    @ViewChild('headerElement', { static: false })
    private headerElement!: ElementRef;
    @ViewChild('sidebarLeftToggleElement', { static: false })
    private sidebarLeftToggleElement!: ElementRef;
    @ViewChild('sidebarRightToggleElement', { static: false })
    private sidebarRightToggleElement!: ElementRef;
    
    constructor (private headerService: HeaderService,private currentStorageService: CurrentStorageService, private router:Router, private authService: AuthService){   
      this.userName = this.currentStorageService.userName;
      this.userFullName = this.currentStorageService.userFullName;
      this.isPdiAdmin = this.authService.isPdiAdmin;  
    }

    ngOnInit(): void {
          
    }
    ngAfterViewInit() {
        this.headerService.elementRef = this.headerElement;
   
    }

    ngOnDestroy() {
        this.subscriptions.map((m) => m.unsubscribe());
    }

    logout(): void {
      if (this.authService.isPdiUser || this.authService.isPdiAdmin)
        this.currentStorageService.userType = "pdi";
      
        this.authService.logout();  
    }
  }