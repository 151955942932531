<div class="row">
    <div class="col-md-12">
        <div class="searchbox">
        <form [formGroup]="formGroup" [keyBoardEventHandler]="keyHandlers">
            <div class="row font-weight-600 mx-auto">
                <div class="col ">
                   <label> Name</label>
                   <input type="text" class="form-control fieldRadius"  placeholder="Last, First"  formControlName="name" (keypress)="keyPressValidation($event,'A')" />
                </div>
                <div class="col">
                   <label>NPI</label> 
                   <input type="text" class="form-control fieldRadius"  placeholder="National Provider ID"  formControlName="npi" (keypress)="keyPressValidation($event,'B')"/>
                </div>
                <div class="col" >
                   <label>TIN</label>
                   <input type="text" class="form-control fieldRadius"  placeholder="Tax ID Number"  formControlName="tin" (keypress)="keyPressValidation($event,'C')"/>
                </div>
                <div class="col">
                    <label class="text-nowrap">Attestation Status</label>
                    <select class="form-control fieldRadius" formControlName="ddlAttestation">
                        <option value="">Select</option>
                        <option value="1">Yes </option>
                        <option value="0">No </option>
                    </select>
                </div>
                <div class="col">
                    <label>Completed</label>
                    <select class="form-control fieldRadius"  formControlName="ddlCompleted">
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No </option>
                    </select>
                </div>
                <div class="col">
                    <label>Assigned To</label>
                    <select class="form-control fieldRadius"  formControlName="ddlAssignedTo">
                        <option *ngFor="let assignTo of assignToList$"
                        value={{assignTo.providerAttestationUserId.toString()}}>{{assignTo.firstName}} {{assignTo.lastName}}</option>
                    </select>
                </div>                
                <div class="col">
                    <label>Modified</label>
                    <select class="form-control fieldRadius"  formControlName="ddlModified">
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div class="col">
                                <div class="d-flex align-items-center mt-4">
                                    <span style="color: #ced4da">|</span>
                                    <div class="cancelcircle px-2" (click)="resetForm()"> <span><i class="fa fa-times" aria-hidden="true" ></i></span></div>
                                    <div class="searchcircle" (click)="searchProvider()"> <span><i class="fa fa-search" aria-hidden="true" ></i></span></div>
                                </div>  
                </div>
            </div>
         </form>
        </div>
    </div>
</div>
<div class="row" style="color: #030d68;">
    <div class="col-md-12 ">
        <span><b>Attestation Period {{this.attestationPeriod}}</b></span>
    </div>
</div>
<div class="row" style="margin-top: 25px !important;">
    <div class="col-md-12 ">
        <ngx-datatable #table
        class="bootstrap pdi-grid"
        [columns]="columns"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [limit]="currentPageSize"
        [rows]="providerAttestations$"
        [sorts]="[{ prop: 'name', dir: 'asc' }]"
        (sort)="onSort($event)"    
        >        
        <ngx-datatable-column name="Name" prop="name" [width]="200">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <a href=javascript:void(0)
            (click)="showDetails(row)"
            ><span class="span-textoverflow">{{row.lastName + ', ' + row.firstName}}</span></a>
            </ng-template>
            </ngx-datatable-column>        
        <ngx-datatable-column name="NPI" prop="npi"></ngx-datatable-column>
        <ngx-datatable-column name="TIN" prop="tin"></ngx-datatable-column>
        <ngx-datatable-column name="Email" prop="email">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                <span class="span-textoverflow">{{value}} </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="CDO" prop="cdo"></ngx-datatable-column>
        <ngx-datatable-column name="AttestationStatus" prop="isAttested">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                {{value==true ? 'Yes' : 'No'}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Completed" prop="completed">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                {{value==true ? 'Yes' : 'No'}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Assigned To" prop="assignedTo"></ngx-datatable-column>
        <ngx-datatable-column name="Modified" prop="modified">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                {{value==true ? 'Yes' : 'No'}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer *ngIf="true">
            <ng-template
            ngx-datatable-footer-template 
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            >
            <table class="w-100">
                <tr class="d-flex align-items-center justify-content-between">
                    <td>            
                        Showing {{(totalCount==0 ? 0 : ((currentPageNumber-1) * pageSize)+1)}} to {{(totalCount>
                            (currentPageNumber*pageSize))?(currentPageNumber*pageSize):(totalCount)}} of
                            {{(totalCount)}} records.
                    </td>
                    <td class="text-end">
                        <datatable-pager
                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'"
                            [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="currentPageNumber"
                            [size]="pageSize"
                            [count]="totalCount"
                            [hidden]="!((totalCount / pageSize) > 1)"
                            (change)="onFooterPage($event)">
                        </datatable-pager>
                    </td>
                    <td class="text-end">
                        <form [formGroup]="formGroup">
                        Records per page                                    
                        <select formControlName="ddlPageSizeOptions" (change)="onPageSizeOptionChange($event)">                            
                        <option *ngFor="let item of  pageSizeOptions" [value]="item">
                            {{item}}
                        </option>                            
                        </select> 
                    </form>
                    </td>
                </tr>                    
            </table>
            </ng-template>
          </ngx-datatable-footer> 
        </ngx-datatable>
    </div>
</div>